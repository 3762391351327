<template>
  <screen-grid class="location-general">
    <screen-card align="center" ratio="1-1" display="grid">
      <template v-slot:body>
        <screen-block
          ratio="1-1"
          :title="$t('locations.id.general.section.activeLocation.label')"
          align="center"
          :has-data="true"
          :class="{
            'location-general__status--active': activeLocation,
            'location-general__status--inactive': !activeLocation,
          }"
          :is-loading="isLoading"
          :is-editable="false"
        >
          <template v-slot:icon>
            <skeleton-icon v-if="isLoading" margin-right="16px" width="36px" height="36px" />
            <div v-else>
              <div class="location-general__status__icon backoffice-icons">location</div>
            </div>
          </template>
          <template v-slot:data>
            <skeleton-line v-if="isLoading" height="18px" margin-top="2px" width="100px" />
            <div class="location-general__status__state" v-else>
              <template v-if="activeLocation">
                {{ $t('locations.id.general.section.activeLocation.status.active') }}
              </template>
              <template v-else-if="!activeLocation">
                {{ $t('locations.id.general.section.activeLocation.status.inactive') }}
              </template>
            </div>
          </template>
          <template v-slot:right>
            <skeleton-line v-if="isLoading" height="18px" width="32px" margin-left="16px" />
            <ui-switch
              v-else
              class="location-general__status__toggle"
              id="active-location"
              :standalone="true"
              :value="activeLocation"
              :input-value="activeLocation"
              @input="updateLocationStatus"
            ></ui-switch>
          </template>
        </screen-block>
      </template>
    </screen-card>
    <screen-card
      :title="$t('locations.id.general.section.generalData.title')"
      ratio="1-1"
      :overflow-hidden="true"
      display="grid"
      :is-loading="isLoading"
    >
      <template v-slot:body>
        <screen-block
          :title="$t('locations.id.general.section.generalData.label.name')"
          icon="article"
          :has-data="typeof currentLocation.name === 'string'"
          :data="currentLocation.name"
          ratio="1-3"
          :is-loading="isLoading"
          :is-required="true"
          @click="editSimpleField('name', 'text', 'input', true)"
        />
        <screen-block
          :title="$t('locations.id.general.section.generalData.label.company')"
          icon="article"
          :has-data="typeof currentLocation.company === 'string' && currentLocation.company !== ''"
          :data="currentLocation.company"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('company', 'text', 'input')"
        />
        <screen-block
          :title="$t('locations.id.general.section.generalData.label.locationType')"
          icon="attribut"
          :is-required="true"
          :has-data="typeof currentLocation.locationType === 'string'"
          :data="currentLocation.locationType"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('locationType', 'text', 'choice', false, null, currentClient.locationTypes)"
        />
        <screen-block
          :title="$t('locations.id.general.section.generalData.label.id')"
          icon="business"
          :has-data="typeof currentLocation.externalId === 'string'"
          :data="currentLocation.externalId"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('externalId', 'text', 'input')"
        />
        <screen-block
          :title="$t('locations.id.general.section.generalData.label.gatoreviewsId')"
          :is-required="true"
          icon="attribut"
          :has-data="typeof currentLocation.gatoreviewsId === 'string' && currentLocation.gatoreviewsId !== ''"
          :data="currentLocation.gatoreviewsId"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('gatoreviewsId', 'text', 'input')"
        />
        <screen-block
          :title="$t('locations.id.general.section.generalData.label.group')"
          icon="article"
          :has-data="typeof currentLocation.group === 'string' && currentLocation.group !== ''"
          :data="currentLocation.group"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('group', 'text', 'input')"
        />
        <screen-block
          :title="$t('locations.id.general.section.generalData.label.address')"
          :is-required="true"
          icon="pin"
          :has-data="typeof currentLocation.street1 === 'string'"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editAddress"
        >
          <template v-slot:data>
            <div>
              {{ currentLocation.street1 }}
            </div>
            <div v-if="currentLocation.street2">
              {{ currentLocation.street2 }}
            </div>
            <div>
              <span v-if="currentLocation.postalCode">
                {{ currentLocation.postalCode }}
              </span>
              {{ currentLocation.city }}
            </div>
            <div>
              <span v-if="currentLocation.countryCode">
                {{ mapCountry(currentLocation.countryCode) }}
              </span>
            </div>
          </template>
        </screen-block>
        <screen-block
          class="location-general__coordinates"
          :is-required="true"
          :title="$t('locations.id.general.section.generalData.label.gps')"
          icon="geoloc"
          :has-data="typeof currentLocation.latitude === 'number' && typeof currentLocation.longitude === 'number'"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editCoordinates"
        >
          <template v-slot:data>
            <div class="location-general__coordinates__section">
              <div class="location-general__coordinates__section__label">
                {{ $t('common.label.latitude') }}
              </div>
              <div class="location-general__coordinates__section__value">
                {{ currentLocation.latitude }}
              </div>
            </div>
            <div class="location-general__coordinates__section">
              <div class="location-general__coordinates__section__label">
                {{ $t('common.label.longitude') }}
              </div>
              <div class="location-general__coordinates__section__value">
                {{ currentLocation.longitude }}
              </div>
            </div>
          </template>
        </screen-block>
        <screen-block
          :title="$t('locations.id.general.section.generalData.label.subDivisions')"
          icon="world"
          :is-required="true"
          :has-data="hasSubdivisions"
          :is-editable="hasSubdivisions"
          ratio="1-3"
          :helper="!hasSubdivisions ? $t('locations.id.general.section.generalData.tooltip.subDivisions') : null"
          :is-loading="isLoading"
          @click="editSubDivisions"
        >
          <template v-slot:data>
            <div v-if="currentLocation.subDivision1">
              {{ mapSubDivision(currentLocation.subDivision1, 'level1') }}
            </div>
            <div v-if="currentLocation.subDivision2">
              {{ mapSubDivision(currentLocation.subDivision2, 'level2') }}
            </div>
            <div v-if="currentLocation.subDivision3">
              {{ mapSubDivision(currentLocation.subDivision3, 'level3') }}
            </div>
          </template>
        </screen-block>
        <screen-block
          :title="$t('locations.id.general.section.generalData.label.phone')"
          icon="phone"
          :has-data="typeof currentLocation.phone === 'string' && currentLocation.phone !== ''"
          :data="currentLocation.phone"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editPhone()"
        />
        <screen-block
          :title="$t('locations.id.general.section.generalData.label.nationalPhone')"
          icon="phone"
          :has-data="typeof currentLocation.nationalPhone === 'string' && currentLocation.nationalPhone !== ''"
          :data="currentLocation.nationalPhone"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('nationalPhone', 'phone', 'input')"
        />
        <screen-block
          :title="$t('locations.id.general.section.generalData.label.internationalPhone')"
          icon="phone"
          :has-data="typeof currentLocation.internationalPhone === 'string'"
          :data="currentLocation.internationalPhone"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('internationalPhone', 'phone', 'input')"
        />
        <screen-block
          :title="$t('locations.id.general.section.generalData.label.phoneInformation')"
          icon="phone"
          :has-data="typeof currentLocation.phoneInformation === 'string'"
          :data="currentLocation.phoneInformation"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('phoneInformation', 'text', 'input')"
        />
        <screen-block
          :title="$t('locations.id.general.section.generalData.label.website')"
          icon="link"
          :has-data="typeof websiteUrl === 'string'"
          :data="websiteUrl"
          ratio="1-3"
          :is-link="true"
          :is-loading="isLoading"
          @click="editWebsite"
        />
        <screen-block
          :title="$t('locations.id.general.section.generalData.label.customLink')"
          icon="link"
          :has-data="typeof currentLocation.customLink === 'string'"
          :data="currentLocation.customLink"
          ratio="1-3"
          :is-link="true"
          :is-loading="isLoading"
          @click="editSimpleField('customLink', 'url', 'input')"
        />
        <screen-block
          :helper="$t('locations.id.general.section.generalData.helpers.email')"
          :title="$t('locations.id.general.section.generalData.label.email')"
          icon="mail"
          :has-data="typeof currentLocation.email === 'string'"
          :data="currentLocation.email"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('email', 'email', 'input')"
        />
        <screen-block
          :helper="$t('locations.id.general.section.generalData.helpers.otherEmail')"
          :title="$t('locations.id.general.section.generalData.label.leadGenerationsEmails')"
          icon="mail"
          :has-data="
            Array.isArray(currentLocation.leadGenerationsEmails) && currentLocation.leadGenerationsEmails.length > 0
          "
          ratio="1-3"
          :is-loading="isLoading"
          @click="editList('leadGenerationsEmails', 'email', 'list', 'email')"
        >
          <template v-slot:data>
            <span v-for="(email, idx) in currentLocation.leadGenerationsEmails" :key="`email-${idx}`">
              {{ email.email }}<template v-if="idx < currentLocation.leadGenerationsEmails.length - 1">, </template>
            </span>
          </template>
        </screen-block>
        <screen-block
          :helper="$t('locations.id.general.section.generalData.helpers.senderName')"
          :title="$t('locations.id.general.section.generalData.label.emailAuthorizeFrom')"
          icon="user"
          :has-data="typeof currentLocation.emailAuthorizeFrom === 'string'"
          :data="currentLocation.emailAuthorizeFrom"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('emailAuthorizeFrom', 'text', 'input')"
        />
        <screen-block
          :title="$t('locations.id.general.section.generalData.label.tags')"
          icon="attribut"
          :has-data="Array.isArray(currentLocation.tags) && currentLocation.tags.length > 0"
          ratio="1-3"
          :is-loading="isLoading"
          @click="
            editSimpleField('tags', 'text', 'list', false, null, frontOffices, {
              label: 'name',
              trackBy: 'id',
              customTag: true,
            })
          "
          v-if="frontOffices.length > 1"
        >
          <template v-slot:data>
            <span v-for="(tag, idx) in frontOfficesTags" :key="`location-tag-${idx}`">
              {{ tag.name }}<template v-if="idx < currentLocation.tags.length - 1">, </template>
            </span>
          </template>
        </screen-block>
        <screen-block
          :title="$t('locations.id.general.section.generalData.label.slug')"
          icon="link"
          :has-data="typeof currentLocation.slug === 'string'"
          :data="currentLocation.slug"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('slug', 'text', 'input')"
        />
        <screen-block
          :title="$t('locations.id.general.section.generalData.label.openingHoursInformation')"
          icon="hours"
          :has-data="typeof currentLocation.openingHoursInformation === 'string'"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('openingHoursInformation', 'text', 'textarea')"
        >
          <template v-slot:data>
            <pre class="location-general__opening-hours-information">{{ currentLocation.openingHoursInformation }}</pre>
          </template>
        </screen-block>
      </template>
    </screen-card>
    <screen-card
      :title="$t('locations.id.general.section.openingHours.title')"
      ratio="1-2"
      display="grid"
      :is-loading="isLoading"
    >
      <template v-slot:body>
        <ui-slider :slides="currentLocationOpeningHours" v-if="currentLocationOpeningHours.length > 0">
          <template v-slot:slide="slotProps">
            <screen-block
              class="location-general__hours"
              :title="
                slotProps.slide.label
                  ? slotProps.slide.label
                  : $tc('locations.id.general.section.openingHours.label.hourSet', 2, {
                      position: slotProps.slide.position,
                    })
              "
              icon="hours"
              :has-data="currentLocationOpeningHours.length > 0"
              ratio="1-1"
              :is-loading="isLoading"
              @click="editOpeningHours(slotProps.slide)"
            >
              <template v-slot:data>
                <div
                  class="location-general__hours__slot"
                  v-for="(slots, day, idx) in slotProps.slide.hours"
                  :key="`opening-hour-${idx}`"
                >
                  <div class="location-general__hours__slot__day">
                    {{ $t(`common.days.${day}`) }}
                  </div>
                  <div class="location-general__hours__slot__time">
                    <div v-if="slots.length === 0">
                      {{ $t('common.label.closed') }}
                    </div>
                    <div
                      v-else-if="
                        slots.length === 1 && slots[0].split('-')[0] === '0:00' && slots[0].split('-')[1] === '0:00'
                      "
                    >
                      {{ $t('common.label.h24') }}
                    </div>
                    <div v-else v-for="(slot, j) in slots" :key="`opening-hour-time-${j}`">
                      {{ slot.split('-')[0] }} - {{ slot.split('-')[1] }}
                    </div>
                  </div>
                </div>
              </template>
            </screen-block>
          </template>
        </ui-slider>
        <screen-block
          v-else
          :title="$tc('locations.id.general.section.openingHours.label.hourSet', 1)"
          icon="hours"
          :has-data="currentLocationOpeningHours.length > 0"
          class="location-general__hours"
          ratio="1-1"
          :is-loading="isLoading"
          :is-editable="false"
        />
        <screen-action
          :label="$t('locations.id.general.section.openingHours.button.addSet')"
          icon="add_hours"
          :is-loading="isLoading"
          @click="editOpeningHours()"
        />
      </template>
    </screen-card>
    <screen-card
      :title="$t('locations.id.general.section.exceptionalOpeningHours.title')"
      ratio="1-2"
      display="grid"
      :is-loading="isLoading"
    >
      <template v-slot:header-right>
        <skeleton-line v-if="isLoading" height="18px" width="32px" margin-left="16px" />
        <ui-dropdown
          class="location-general__dropdown"
          id="dropdown-exceptional-hours-period"
          v-else
          v-model="exceptionalHoursPeriod"
          track-by="id"
          label="label"
          :options="exceptionalHoursPeriodOptions"
          :placeholder="$t('locations.id.general.section.exceptionalOpeningHours.period.placeholder')"
          @input="sortExceptionalOpeningHour"
        />
      </template>
      <template v-slot:body>
        <screen-block
          v-for="(exceptionalHour, idx) in currentLocationExceptionalOpeningHours"
          :key="`exceptional-hour-${idx}`"
          :title="exceptionalHour.label"
          icon="date"
          :has-data="currentLocationExceptionalOpeningHours.length > 0"
          class="location-general__hours"
          ratio="1-1"
          :is-loading="isLoading"
          @click="editExceptionalHours(exceptionalHour)"
        >
          <template v-slot:data>
            <div class="location-general__hours__slot">
              <div class="location-general__hours__slot__day">
                <template
                  v-if="
                    exceptionalHour.dateFrom === exceptionalHour.dateTo ||
                    (exceptionalHour.dateFrom && !exceptionalHour.dateTo)
                  "
                >
                  {{ $t('common.date.on') }} {{ date(exceptionalHour.dateFrom) }}
                </template>
                <template v-else>
                  {{ $t('common.date.from') }} {{ date(exceptionalHour.dateFrom) }} {{ $t('common.date.to') }}
                  {{ date(exceptionalHour.dateTo) }}
                </template>
              </div>
              <div class="location-general__hours__slot__time">
                <div v-if="!exceptionalHour.openStatus">
                  {{ $t('common.label.closed') }}
                </div>
                <div v-else-if="!exceptionalHour.hours1 && !exceptionalHour.hours2">
                  {{ $t('common.label.open') }}
                </div>
                <template v-else>
                  <div v-if="exceptionalHour.hours1">
                    {{ exceptionalHour.hours1.split('-')[0] }} - {{ exceptionalHour.hours1.split('-')[1] }}
                  </div>
                  <div v-if="exceptionalHour.hours2">
                    {{ exceptionalHour.hours2.split('-')[0] }} - {{ exceptionalHour.hours2.split('-')[1] }}
                  </div>
                </template>
              </div>
            </div>
          </template>
        </screen-block>
        <screen-block
          v-if="currentLocationExceptionalOpeningHours.length === 0"
          :title="$tc('locations.id.general.section.exceptionalOpeningHours.label.hourSet', 1)"
          icon="date"
          :has-data="currentLocationExceptionalOpeningHours.length > 0"
          ratio="1-1"
          :is-loading="isLoading"
          :is-editable="false"
        />
        <screen-action
          :label="$t('locations.id.general.section.exceptionalOpeningHours.button.addSet')"
          icon="add_hours"
          :is-loading="isLoading"
          @click="editExceptionalHours()"
        />
      </template>
    </screen-card>
  </screen-grid>
</template>

<script>
import UiSwitch from '@/components/UI/Switch.vue'
import UiSlider from '@/components/UI/Slider.vue'
import UiDropdown from '@/components/UI/Dropdown.vue'
import SkeletonLine from '@/components/Skeleton/Line.vue'
import SkeletonIcon from '@/components/Skeleton/Icon.vue'
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import ScreenBlock from '@/components/Screen/Block.vue'
import ScreenAction from '@/components/Screen/Action.vue'
import { formatedDate } from '@/utils/date.util'

export default {
  name: 'LocationGeneral',
  components: {
    UiSwitch,
    UiSlider,
    UiDropdown,
    SkeletonLine,
    ScreenGrid,
    ScreenCard,
    ScreenBlock,
    ScreenAction,
    SkeletonIcon,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentLocation: {
      type: Object,
      required: true,
    },
    currentLocationUrls: {
      type: Array,
      required: true,
    },
    currentLocationOpeningHours: {
      type: Array,
      required: true,
    },
    currentLocationExceptionalOpeningHours: {
      type: Array,
      required: true,
    },
    currentClient: {
      type: Object,
      required: true,
    },
    countries: {
      type: Array,
      required: true,
    },
    frontOffices: {
      type: Array,
      required: true,
    },
    subDivisions: {
      type: [Object, Array],
      required: false,
      default: null,
    },
    externalModules: {
      type: Object,
      required: false,
      default: null,
    },
    externalLinksProvider: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      activeGmbPage: false,
      exceptionalHoursPeriod: {
        id: 'current',
        label: this.$t('locations.id.general.section.exceptionalOpeningHours.period.current'),
      },
      exceptionalHoursPeriodOptions: [
        { id: 'current', label: this.$t('locations.id.general.section.exceptionalOpeningHours.period.current') },
        { id: 'deprecated', label: this.$t('locations.id.general.section.exceptionalOpeningHours.period.deprecated') },
        { id: 'future', label: this.$t('locations.id.general.section.exceptionalOpeningHours.period.future') },
        { id: 'all', label: this.$t('locations.id.general.section.exceptionalOpeningHours.period.all') },
      ],
    }
  },
  computed: {
    frontOfficesTags() {
      let frontOfficesTagsList = []
      this.currentLocation.tags.forEach(currentLocationTag => {
        this.frontOffices.filter(frontOfficesTags =>
          frontOfficesTags.tag === currentLocationTag ? frontOfficesTagsList.push(frontOfficesTags) : null
        )
      })
      return frontOfficesTagsList
    },
    activeLocation() {
      if (this.currentLocation.status === '0') {
        return false
      }
      return true
    },
    websiteUrl() {
      const url = this.currentLocationUrls.find(url => url.source === 'Website')
      return url ? url.sourceUrl : null
    },
    currentLocationUrlsWithoutWebsite() {
      return this.currentLocationUrls.filter(url => url.source !== 'Website')
    },
    hasSubdivisions() {
      return (
        typeof this.currentLocation.subDivision1 === 'string' &&
        this.currentLocation.subDivision1 !== '' &&
        this.subDivisions !== null
      )
    },
  },
  methods: {
    editSimpleField(objKey, type, mode, required = false, number = null, options = [], dropdown = {}) {
      this.$emit('editSimpleField', {
        objKey,
        parameters: {
          type,
          mode,
          required,
          number,
          options,
          dropdown,
        },
      })
    },
    editList(objKey, type, mode, listKey = null) {
      this.$emit('editSimpleField', {
        objKey,
        parameters: {
          type,
          mode,
          listKey,
        },
      })
    },
    editAddress() {
      this.$emit('editAddress', {
        address: {
          street1: this.currentLocation.street1,
          street2: this.currentLocation.street2,
          postalCode: this.currentLocation.postalCode,
          city: this.currentLocation.city,
          countryCode: this.currentLocation.countryCode,
        },
      })
    },
    editPhone() {
      this.$emit('editPhone', {
        phone: this.currentLocation.phone,
        countryCode: this.currentLocation.countryCode,
      })
    },
    editCoordinates() {
      this.$emit('editCoordinates', {
        coordinates: {
          street1: this.currentLocation.street1,
          street2: this.currentLocation.street2,
          postalCode: this.currentLocation.postalCode,
          city: this.currentLocation.city,
          countryCode: this.currentLocation.countryCode,
          latitude: this.currentLocation.latitude,
          longitude: this.currentLocation.longitude,
        },
      })
    },
    editOpeningHours(hourSet) {
      this.$emit('editOpeningHours', {
        hours: hourSet || {
          position: this.currentLocationOpeningHours.length + 1,
          locationId: this.currentLocation.id,
        },
      })
    },
    editWebsite() {
      const url = this.currentLocationUrls.find(url => url.source === 'Website')

      this.$emit('editWebsite', {
        url: url || { source: 'Website', sourceUrl: '', locationId: this.currentLocation.id },
      })
    },
    editExceptionalHours(exceptionalHour) {
      this.$emit('editExceptionalHours', {
        exceptionalHour: exceptionalHour || { locationId: this.currentLocation.id },
      })
    },
    editSubDivisions() {
      this.$emit('editSubDivisions', {
        subDivisions: this.subDivisions,
        locationDivisions: {
          subDivision1: this.currentLocation.subDivision1,
          subDivision2: this.currentLocation.subDivision2,
          subDivision3: this.currentLocation.subDivision3,
        },
      })
    },
    date(date) {
      return formatedDate(date, 'L', this.$i18n.locale)
    },
    mapCountry(countryCode) {
      const country = this.countries.find(country => country.countryCode === countryCode)
      return country ? country.name : countryCode
    },
    mapSubDivision(division, level) {
      let subDivision = null
      if (level === 'level1') {
        subDivision = this.subDivisions[level]?.find(sub => sub.code === division)
      } else if (level === 'level2') {
        subDivision = this.subDivisions[level]?.find(
          sub => sub.code === division && sub.code_up === this.currentLocation.subDivision1
        )
      } else if (level === 'level3') {
        subDivision = this.subDivisions[level]?.find(
          sub => sub.code === division && sub.code_up === this.currentLocation.subDivision2
        )
      }
      return subDivision ? subDivision.name : this.$t('errors.subDivisions')
    },
    sortExceptionalOpeningHour(period) {
      this.$emit('sortExceptionalOpeningHour', period.id)
    },
    updateLocationStatus(value) {
      const status = value ? '1' : '0'
      this.$emit('updateLocationStatus', status)
    },
  },
}
</script>

<style lang="scss" scoped>
.location-general {
  &__status {
    &__icon {
      margin-right: $gutter-mobile;
      border: 1px solid;
      border-radius: 50%;
      padding: 5px;
      width: 36px;
      height: 36px;

      .location-general__status--active & {
        color: map-get($generic-color-variants, 'success');
      }

      .location-general__status--inactive & {
        color: map-get($generic-color-variants, 'error');
      }
    }

    &__state {
      margin-top: 2px;

      .location-general__status--active & {
        color: map-get($generic-color-variants, 'success');
      }

      .location-general__status--inactive & {
        color: map-get($generic-color-variants, 'error');
      }
    }

    &__toggle {
      margin-left: $gutter-mobile;
    }
  }

  &__coordinates {
    &__section {
      display: flex;
      justify-content: space-between;

      &__label {
        font-weight: 500;
      }
    }
  }

  &__opening-hours-information {
    @include text-ellipsis(3);

    margin: 0;
    font-family: inherit;
  }

  &__hours {
    &__slot {
      display: flex;
      justify-content: space-between;
      margin-bottom: $gutter-mobile / 2;

      &:last-child {
        margin-bottom: 0;
      }

      &__day {
        font-weight: 500;
      }

      &__time {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }
  }

  &__dropdown {
    max-width: 160px;
  }
}
</style>
