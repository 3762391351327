import { formatedDate } from '@/utils/date.util'

/**
 * Returns the element array for any media
 * @param {string} items items to parse
 * @param {string} type type of media
 * @param {string} status action status (add, edit, delete)
 * @param {string} itemId item to edit or remove
 * @returns {string} Parsed and formatted time
 */
export const mediaParser = (items, type, status, selectedItem, mediaUrl = null) => {
  if (status === 'delete') {
    if (type === 'locationPictures') {
      return items.filter(item => item !== selectedItem.picture)
    }
    return items.filter(item => item.id !== selectedItem.id)
  } else if (status === 'edit') {
    const index = items.findIndex(item => item.id === selectedItem.id)
    const cloneItems = [...items]
    if (type === 'event') {
      cloneItems[index] = {
        id: selectedItem.id,
        title: selectedItem.title,
        link: selectedItem.link,
        description: selectedItem.description,
        picture: mediaUrl,
        from: selectedItem.from ? formatedDate(selectedItem.from, 'YYYY-MM-DD') : '',
        to: selectedItem.to ? formatedDate(selectedItem.to, 'YYYY-MM-DD') : '',
      }
    } else if (type === 'hotPictures') {
      cloneItems[index] = {
        id: selectedItem.id,
        name: selectedItem.title,
        url: selectedItem.link,
        picture_url: mediaUrl,
      }
    } else if (type === 'team') {
      cloneItems[index] = {
        id: selectedItem.id,
        title: selectedItem.title,
        link: selectedItem.link,
        picture: mediaUrl,
        description: selectedItem.description,
      }
    }
    return cloneItems
  } else if (status === 'add') {
    const cloneItems = items ? [...items] : []
    if (type === 'event') {
      cloneItems.push({
        id: `event-${cloneItems.length + 1}-${formatedDate('now', 'x')}`,
        title: selectedItem.title,
        link: selectedItem.link,
        description: selectedItem.description,
        picture: mediaUrl,
        from: selectedItem.from ? formatedDate(selectedItem.from, 'YYYY-MM-DD') : '',
        to: selectedItem.to ? formatedDate(selectedItem.to, 'YYYY-MM-DD') : '',
      })
    } else if (type === 'hotPictures') {
      cloneItems.push({
        id: `product-${cloneItems.length + 1}-${formatedDate('now', 'x')}`,
        name: selectedItem.title,
        url: selectedItem.link,
        picture_url: mediaUrl,
      })
    } else if (type === 'team') {
      cloneItems.push({
        id: `team-${cloneItems.length + 1}-${formatedDate('now', 'x')}`,
        title: selectedItem.title,
        link: selectedItem.link,
        description: selectedItem.description,
        picture: mediaUrl,
      })
    } else if (type === 'locationPictures') {
      cloneItems.push(mediaUrl)
    }
    return cloneItems
  } else if (status === 'clone') {
    if (type === 'event') {
      return {
        id: selectedItem.id,
        title: selectedItem.title,
        link: selectedItem.link,
        description: selectedItem.description,
        picture: mediaUrl,
        from: selectedItem.from ? formatedDate(selectedItem.from, 'YYYY-MM-DD') : '',
        to: selectedItem.to ? formatedDate(selectedItem.to, 'YYYY-MM-DD') : '',
      }
    } else if (type === 'hotPictures') {
      return {
        id: selectedItem.id,
        name: selectedItem.title,
        url: selectedItem.link,
        picture_url: mediaUrl,
      }
    } else if (type === 'locationPictures') {
      return mediaUrl
    }
  }
}
