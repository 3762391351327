<template>
  <div class="feature-list-selection" :class="{ 'feature-list-selection--row': displaymode === 'grid' }">
    <div class="feature-list-selection__section" v-for="(feature, idx) in features" :key="`feature-${idx}`">
      <template v-if="parameters.mode === 'frontoffice'">
        <ui-switch
          class="feature-list-selection__section__switch"
          :id="`switch-${idx}`"
          v-model="modelActiveList"
          :value="feature.tag"
          :reverse="true"
          align="space-between"
          @input="setActiveList(feature.tag)"
        >
          {{ getTitle(feature) }}
        </ui-switch>
        <ui-checkbox
          class="feature-list-selection__section__checkbox"
          :id="`checkbox-${idx}-${j}`"
          :input-value="getItems(feature.list, feature.tag, exceptions)"
          :value="item"
          :reverse="true"
          align="space-between"
          v-for="(item, j) in feature.list"
          :key="`item-${idx}-${j}`"
          :disabled="!(modelActiveList || []).includes(feature.tag)"
          @input="setExceptions($event, feature.tag, feature.list)"
        >
          <template v-if="getItemIfExist(feature, item)">
            <div class="feature-list-selection__section__checkbox__item">
              <img
                class="feature-list-selection__section__checkbox__item__picture"
                :src="getItemIfExist(feature, item).picture"
              />
              <span class="feature-list-selection__section__checkbox__item__text">
                {{ getItemIfExist(feature, item).title }}
              </span>
            </div>
          </template>
          <template v-else>
            {{ item.replace('_', ' ') }}
          </template>
        </ui-checkbox>
      </template>

      <template v-if="parameters.mode === 'location'">
        <ui-checkbox
          class="feature-list-selection__section__label"
          :id="`checkbox-${idx}`"
          :input-value="areAllFeaturesSelected(feature.tag)"
          :value="feature.tag"
          :reverse="true"
          align="space-between"
          @input="toggleFeatureListSelection($event, feature.tag)"
        >
          {{ getTitle(feature) }}
        </ui-checkbox>
        <ui-checkbox
          class="feature-list-selection__section__checkbox"
          :id="`checkbox-${idx}-${j}`"
          v-model="modelActiveList[feature.tag]"
          :value="item"
          :reverse="true"
          align="space-between"
          v-for="(item, j) in feature.list"
          :key="`item-${idx}-${j}`"
          @input="setExceptions($event, feature.tag, feature.list)"
        >
          <template v-if="getItemIfExist(feature, item)">
            <div class="feature-list-selection__section__checkbox__item">
              <img
                class="feature-list-selection__section__checkbox__item__picture"
                :src="getItemIfExist(feature, item).picture"
              />
              <span class="feature-list-selection__section__checkbox__item__text">
                {{ getItemIfExist(feature, item).title }}
              </span>
            </div>
          </template>
          <template v-else>
            {{ item.replace('_', ' ') }}
          </template>
        </ui-checkbox>
      </template>
    </div>
  </div>
</template>

<script>
import UiSwitch from '@/components/UI/Switch.vue'
import UiCheckbox from '@/components/UI/Checkbox.vue'

export default {
  name: 'FeatureListSelection',
  components: {
    UiSwitch,
    UiCheckbox,
  },
  props: {
    displaymode: {
      type: String,
      required: false,
      default: '',
    },
    features: {
      type: Array,
      required: true,
    },
    activeList: {
      type: [Array, Object],
      required: false,
      default: () => [],
    },
    exceptions: {
      type: Object,
      required: false,
      default: () => {},
    },
    parameters: {
      type: Object,
      required: false,
      default: () => ({
        mode: '',
      }),
    },
  },
  data() {
    return {
      modelActiveList: null,
      modelActiveListDetails: null,
      modelExceptions: null,
    }
  },
  created() {
    if (this.parameters.mode === 'frontoffice') {
      this.modelActiveList = this.activeList
    } else {
      this.modelActiveList = this.features.reduce(
        (modelActiveList, feature) => ({
          ...modelActiveList,
          [feature.tag]: (this.activeList || [])[feature.tag] ?? [],
        }),
        {}
      )
    }

    this.features.forEach(feature => {
      if (feature.items?.length > 0) {
        let modelActiveItemsDetails = []
        this.activeList[feature.tag]?.forEach(item => modelActiveItemsDetails.push(this.getItemIfExist(feature, item)))
        this.modelActiveListDetails = {
          [feature.tag]: {
            title: feature.title,
            items: modelActiveItemsDetails,
          },
        }
      }
    })

    this.modelExceptions = { ...this.exceptions }
    this.inputDataChanged()
  },
  methods: {
    getTitle(feature) {
      if (this.modelActiveListDetails !== null) {
        return this.modelActiveListDetails[feature.tag] ? this.modelActiveListDetails[feature.tag].title : feature.tag
      }
      return feature.tag
    },
    getItemIfExist(feature, i) {
      if (feature.items?.length > 0) {
        return feature.items.find(item => item.key === i)
      }
      return null
    },
    inputDataChanged() {
      this.$emit('inputDataChanged', {
        exceptions: this.modelExceptions,
        activeList: this.modelActiveList,
        activeListDetails: this.modelActiveListDetails,
      })
    },
    areAllFeaturesSelected(featureTag) {
      const feature = this.features.find(feature => feature.tag === featureTag) || { list: [] }
      return feature.list.length === this.modelActiveList[featureTag].length
    },
    getItems(list, key, exceptions) {
      if (exceptions[key]) {
        return list.filter(l => !exceptions[key].includes(l))
      }
      return list
    },
    setExceptions(items, key, list) {
      const feature = this.features.find(feature => feature.tag === key)

      if (this.modelActiveListDetails !== null) {
        this.modelActiveListDetails[key] ? (this.modelActiveListDetails[key].items = []) : null

        items.forEach(item => {
          const foundItem = this.getItemIfExist(feature, item)
          if (foundItem !== null) {
            this.modelActiveListDetails[key].items.push(foundItem)
          }
        })
      }

      this.modelExceptions = {
        ...this.modelExceptions,
        [key]: list.filter(l => !items.includes(l)),
      }
      this.inputDataChanged()
    },
    setActiveList(key) {
      delete this.modelExceptions[key]
      this.inputDataChanged()
    },
    toggleFeatureListSelection(isFeatureTagSelected, featureTag) {
      const feature = this.features.find(feature => feature.tag === featureTag)
      this.modelActiveList[featureTag] = isFeatureTagSelected ? feature.list : []

      if (this.modelActiveListDetails !== null) {
        this.modelActiveListDetails[featureTag] ? (this.modelActiveListDetails[featureTag].items = []) : null

        if (isFeatureTagSelected) {
          feature.list.forEach(item => {
            const foundItem = this.getItemIfExist(feature, item)
            if (foundItem !== null) {
              this.modelActiveListDetails[featureTag].items.push(foundItem)
            }
          })
        }
      }
      this.inputDataChanged()
    },
  },
}
</script>

<style lang="scss" scoped>
.feature-list-selection {
  &--row {
    display: flex;
    flex-wrap: wrap;
    row-gap: 30px;
  }

  &__section {
    display: flex;
    flex-direction: column;
    margin-bottom: $gutter-mobile;

    @media (min-width: $screen-sm) {
      margin-bottom: $gutter-tablet;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &__switch,
    &__label {
      text-transform: capitalize;
      font-weight: 600;
    }

    &__checkbox {
      margin-top: 10px;
      text-transform: capitalize;

      &__item {
        display: flex;
        gap: 20px;
        align-items: center;

        &__picture {
          max-width: 120px;
          max-height: 60px;
        }

        &__text {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
</style>
