<template>
  <modal
    name="modal-sub-divisions"
    :title="title"
    :has-apply="true"
    :is-updating="isUpdating"
    @save="save"
    @closed="closed"
  >
    <template v-slot:container>
      <ui-dropdown
        class="modal-sub-divisions__dropdown"
        :value="modelLevel1"
        :options="optionsLevel1"
        label="name"
        track-by="code"
        :placeholder="$t('modal.subDivisions.label.level1.placeholder')"
        :dropdown-label="$t('modal.subDivisions.label.level1.dropdown')"
        :show-label="true"
        :no-absolute="true"
        @input="updateLevel($event, 'level1')"
      />
      <ui-dropdown
        class="modal-sub-divisions__dropdown"
        v-if="optionsLevel2.length > 0"
        :value="modelLevel2"
        :options="optionsLevel2"
        label="name"
        track-by="code"
        :placeholder="$t('modal.subDivisions.label.level2.placeholder')"
        :dropdown-label="$t('modal.subDivisions.label.level2.dropdown')"
        :show-label="true"
        :no-absolute="true"
        @input="updateLevel($event, 'level2')"
      />
      <ui-dropdown
        class="modal-sub-divisions__dropdown"
        v-if="optionsLevel3.length > 0"
        :value="modelLevel3"
        :options="optionsLevel3"
        label="name"
        track-by="code"
        :placeholder="$t('modal.subDivisions.label.level3.placeholder')"
        :dropdown-label="$t('modal.subDivisions.label.level3.dropdown')"
        :show-label="true"
        :no-absolute="true"
        @input="updateLevel($event, 'level3')"
      />

      <div class="modal-sub-divisions__actions">
        <ui-button
          class="modal-sub-divisions__actions__cta"
          button="primary"
          variant="data"
          :label="$t('modal.subDivisions.button.generate')"
          :disabled="locationCoordinatesAreUndefined || isCoordinateUpdating"
          @click="generateSubDivisions"
        />
      </div>
    </template>
  </modal>
</template>

<script>
import { getGeonames } from '@/services/tool.service'
import UiDropdown from '@/components/UI/Dropdown.vue'
import UiButton from '@/components/UI/Button.vue'

export default {
  name: 'ModalSubDivisions',
  components: {
    UiDropdown,
    UiButton,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subDivisions: {
      type: Object,
      required: true,
    },
    currentCoordinates: {
      type: Object,
      required: false,
    },
    data: {
      type: Object,
      required: false,
      default: () => ({
        subDivision1: '',
        subDivision2: '',
        subDivision3: '',
      }),
    },
    isUpdating: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      modelData: null,
      isCoordinateUpdating: false,
    }
  },
  mounted() {
    this.modelData = Object.assign(
      {
        subDivision1: '',
        subDivision2: '',
        subDivision3: '',
      },
      this.data
    )
  },
  computed: {
    modelLevel1() {
      return this.subDivisions.level1.find(division => division.code === this.modelData.subDivision1)
    },
    modelLevel2() {
      return this.subDivisions.level2.find(
        division => division.code === this.modelData.subDivision2 && division.code_up === this.modelData.subDivision1
      )
    },
    modelLevel3() {
      return this.subDivisions.level3.find(
        division => division.code === this.modelData.subDivision3 && division.code_up === this.modelData.subDivision2
      )
    },
    optionsLevel1() {
      return this.subDivisions.level1
    },
    optionsLevel2() {
      return this.modelLevel1
        ? this.subDivisions.level2.filter(division => division.code_up === this.modelLevel1.code)
        : []
    },
    optionsLevel3() {
      return this.modelLevel2
        ? this.subDivisions.level3.filter(division => division.code_up === this.modelLevel2.code)
        : []
    },
    locationCoordinatesAreUndefined() {
      return !this.currentCoordinates?.latitude || !this.currentCoordinates?.longitude
    },
  },
  methods: {
    save() {
      this.$emit('save', {
        objKey: this.modelData,
      })
    },
    closed() {
      this.$emit('closed')
    },
    setTags(tags) {
      this.data = tags
    },
    updateLevel(division, level) {
      if (level === 'level1' && division !== this.modelLevel1) {
        this.modelData = {
          ...this.modelData,
          subDivision1: division.code,
          subDivision2: '',
          subDivision3: '',
        }
      } else if (level === 'level2' && division !== this.modelLevel2) {
        this.modelData = {
          ...this.modelData,
          subDivision2: division.code,
          subDivision3: '',
        }
      } else if (level === 'level3' && division !== this.modelLevel3) {
        this.modelData = {
          ...this.modelData,
          subDivision3: division.code,
        }
      }
    },
    async generateSubDivisions() {
      this.isCoordinateUpdating = true
      const params = {
        countryCode: this.currentCoordinates.countryCode,
        latitude: this.currentCoordinates.latitude,
        longitude: this.currentCoordinates.longitude,
      }
      try {
        const { data: geoname } = await getGeonames(params)
        Object.entries(geoname).map((subDivisionCode, index) => {
          const level = 'level' + (index + 1)
          const division = {
            code: subDivisionCode[1],
          }
          this.updateLevel(division, level)
        })
      } catch (err) {
        this.$log.error(err)
      }
      this.isCoordinateUpdating = false
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-sub-divisions {
  &__dropdown {
    margin-bottom: $gutter-mobile;

    @media (min-width: $screen-sm) {
      margin-bottom: $gutter-tablet;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
    margin-top: $gutter-mobile;

    @media (min-width: $screen-sm) {
      margin-top: $gutter-tablet;
    }
  }
}
</style>
