<template>
  <modal
    name="modal-external-modules"
    :title="title"
    :has-apply="true"
    :is-updating="isUpdating"
    :has-delete="canBeDeleted"
    :delete-label="$t('modal.externalModules.delete.label')"
    :delete-tooltip="$t('modal.externalModules.delete.tooltip')"
    @save="save"
    @remove="remove"
    @closed="closed"
  >
    <template v-slot:container>
      <ui-dropdown
        class="modal-external-modules__input"
        v-model="modelData.key"
        :options="modulesOptions"
        :placeholder="$t('modal.externalModules.label.module.placeholder')"
        :dropdown-label="$t('modal.externalModules.label.module.dropdown')"
        :disabled="modelData.key && modelData.key !== '' && canBeDeleted"
        :show-label="true"
      />
      <ui-input
        class="modal-external-modules__input"
        v-model="modelData.value"
        :label="$t('modal.externalModules.label.value')"
        id="modal-module-value"
        :error="$v.modelData.value.$error"
        @enter="save"
      >
        <template v-if="$v.modelData.value.$error && !$v.modelData.value.required">
          {{ $t('errors.required') }}
        </template>
      </ui-input>
    </template>
  </modal>
</template>

<script>
import UiDropdown from '@/components/UI/Dropdown.vue'
import UiInput from '@/components/UI/Input.vue'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'ModalExternalModules',
  components: {
    UiDropdown,
    UiInput,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    externalModules: {
      type: Object,
      required: true,
    },
    locationModules: {
      type: Object,
      required: false,
      default: null,
    },
    data: {
      type: Object,
      required: false,
      default: () => ({
        key: '',
        value: '',
      }),
    },
    isUpdating: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      modelData: null,
      canBeDeleted: false,
    }
  },
  mounted() {
    this.modelData = Object.assign(
      {
        key: '',
        value: '',
      },
      this.data
    )
    if (this.modelData.key && this.modelData.key !== '') {
      this.canBeDeleted = true
    }
  },
  computed: {
    modulesOptions() {
      const allModules = Object.values(this.externalModules)
      const locationsModules = this.locationModules ? Object.keys(this.locationModules) : []
      return allModules.filter(module => !locationsModules.includes(module))
    },
  },
  methods: {
    save() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$emit('save', {
          objKey: {
            external: {
              ...this.locationModules,
              [this.modelData.key]: this.modelData.value,
            },
          },
        })
        this.$v.$reset()
      }
    },
    remove() {
      const external = Object.assign({}, this.locationModules)
      delete external[this.modelData.key]
      this.$emit('save', {
        objKey: {
          external,
        },
      })
    },
    closed() {
      this.$emit('closed')
    },
  },
  validations() {
    return {
      modelData: {
        value: {
          required,
        },
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.modal-external-modules {
  &__input {
    margin-bottom: $gutter-mobile;

    @media (min-width: $screen-sm) {
      margin-bottom: $gutter-tablet;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
