<template>
  <div
    class="screen-flux"
    :class="[`screen-flux--${ratio}`, { 'screen-flux--is-editable': isEditable }]"
    :style="style"
    @click="isEditable ? onClick() : false"
  >
    <skeleton-line v-if="isLoading" :no-radius="true" height="100px" width="100%" max-width="initial" />
    <img :src="picture" class="screen-flux__img" v-if="picture" />

    <div class="screen-flux__content" v-if="title">
      <skeleton-line v-if="isLoading" height="18px" width="60%" class="screen-flux__title" />
      <div class="screen-flux__content__title" v-else>
        {{ title }}
      </div>
      <skeleton-line
        v-if="isLoading"
        height="18px"
        width="calc(100% - 24px)"
        class="screen-flux__content__description"
        max-width="initial"
      />
      <div class="screen-flux__content__description" v-if="description && !isLoading">
        {{ description }}
      </div>
      <div class="screen-flux__content__link" v-if="link">
        <span class="screen-flux__content__link__icon backoffice-icons">
          {{ icon }}
        </span>
        <a class="screen-flux__content__link__url" :href="link" @click.stop target="_blank">
          {{ link }}
        </a>
      </div>
      <div class="screen-flux__content__date" v-if="from || to">
        <span class="screen-flux__content__date__icon backoffice-icons"> calendar </span>
        <div class="screen-flux__content__date__from" v-if="from">
          <span v-if="from && to">
            {{ $t('common.date.from') }}
          </span>
          <span v-else-if="from && !to">
            {{ $t('common.date.starting') }}
          </span>
          {{ date(from) }}
        </div>
        <div class="screen-flux__content__date__to" v-if="to">
          <span v-if="from && to">
            {{ $t('common.date.to') }}
          </span>
          <span v-else-if="!from && to">
            {{ $t('common.date.ending') }}
          </span>
          {{ date(to) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { productColor } from '@/config/productColor.config'
import SkeletonLine from '@/components/Skeleton/Line.vue'
import { formatedDate } from '@/utils/date.util'

export default {
  name: 'ScreenFlux',
  components: {
    SkeletonLine,
  },
  props: {
    icon: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    description: {
      type: String,
      required: false,
      default: '',
    },
    picture: {
      type: String,
      required: false,
      default: '',
    },
    link: {
      type: String,
      required: false,
      default: '',
    },
    isEditable: {
      type: Boolean,
      required: false,
      default: true,
    },
    ratio: {
      type: String,
      required: false,
      default: '',
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    from: {
      type: String,
      required: false,
      default: '',
    },
    to: {
      type: String,
      required: false,
      default: '',
    },
    locale: {
      type: String,
      required: false,
      default: 'fr',
    },
  },
  computed: {
    style() {
      return {
        '--product-color': productColor[this.productLine],
      }
    },
    productLine() {
      return this.$route.meta.productLine || 'none'
    },
  },
  methods: {
    onClick() {
      this.$emit('click')
    },
    date(date) {
      return formatedDate(date, 'L', this.locale)
    },
  },
}
</script>

<style lang="scss" scoped>
.screen-flux {
  display: flex;
  flex-direction: column;
  transition: background-color $transition-duration-fast $transition-effect-default;
  border-radius: $radius-default;
  box-shadow: 0 0 8px var(--box-shadow-color);
  width: 100%;
  min-height: 42px;
  overflow: hidden;

  &--is-editable {
    &:hover {
      box-shadow: 0 0 16px var(--box-shadow-color);
      background-color: var(--bg-color-hover);
      cursor: pointer;
    }
  }

  &--1-1,
  &--1-2,
  &--1-3,
  &--2-3 {
    margin: $gutter-mobile / 2;

    @media (min-width: $screen-sm) {
      margin: $gutter-tablet / 2;
    }

    @media (min-width: $screen-xl) {
      margin: $gutter-desktop / 2;
    }
  }

  &--1-2 {
    @media (min-width: $screen-sm) {
      width: calc(100% / 2 - #{$gutter-tablet});
    }

    @media (min-width: $screen-xl) {
      width: calc(100% / 2 - #{$gutter-desktop});
    }
  }

  &--1-3 {
    @media (min-width: $screen-sm) {
      width: calc(100% / 2 - #{$gutter-tablet});
    }

    @media (min-width: $screen-md) {
      width: calc(100% / 3 - #{$gutter-tablet});
    }

    @media (min-width: $screen-xl) {
      width: calc(100% / 3 - #{$gutter-desktop});
    }
  }

  &--2-3 {
    @media (min-width: $screen-sm) {
      width: calc(100% * (2 / 3) - #{$gutter-tablet});
    }

    @media (min-width: $screen-xl) {
      width: calc(100% * (2 / 3) - #{$gutter-desktop});
    }
  }

  &__img {
    display: inline-flex;
    transition: filter $transition-duration-fast $transition-effect-default;
    width: 100%;

    .screen-flux--is-editable:hover & {
      filter: brightness(0.8);
    }
  }

  &__content {
    margin: 12px;

    &__title {
      font-weight: 600;
    }

    &__description {
      @include text-ellipsis(3, 14px);

      margin-top: 8px;
      color: var(--text-color-alt);
    }

    &__link,
    &__date {
      display: flex;
      align-items: center;
      margin-top: 8px;

      &__icon {
        margin-right: 8px;
        color: var(--product-color);
        font-size: $font-size-lg;
      }

      &__url {
        @include text-ellipsis(1, 12px);

        color: var(--text-color-alt);
        font-size: $font-size-sm;

        &:hover {
          color: var(--product-color);
        }
      }
    }

    &__date {
      &__from,
      &__to {
        color: var(--text-color-alt);
        font-size: $font-size-sm;
      }

      &__from {
        margin-right: 4px;
      }
    }
  }
}
</style>
