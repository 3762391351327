<template>
  <modal
    name="modal-url"
    :title="title"
    :has-apply="true"
    :has-delete="canBeDeleted"
    :is-updating="isUpdating"
    :delete-label="$t('modal.url.delete.label')"
    :delete-tooltip="$t('modal.url.delete.tooltip')"
    @save="save"
    @remove="remove"
    @closed="closed"
  >
    <template v-slot:container>
      <ui-input
        class="modal-url__input"
        v-model.trim="modelData.sourceUrl"
        :label="label"
        id="modal-url"
        :error="$v.modelData.sourceUrl.$error"
        @enter="save"
        type="url"
      >
        <template v-if="$v.modelData.sourceUrl.$error && !$v.modelData.sourceUrl.required">
          {{ $t('errors.required') }}
        </template>
        <template v-if="$v.modelData.sourceUrl.$error && !$v.modelData.sourceUrl.url">
          {{ $t('errors.url') }}
        </template>
      </ui-input>
    </template>
  </modal>
</template>

<script>
import UiInput from '@/components/UI/Input.vue'
import { required, url } from 'vuelidate/lib/validators'

export default {
  name: 'ModalUrl',
  components: {
    UiInput,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    label: {
      type: [String, Object],
      required: true,
    },
    data: {
      type: Object,
      required: false,
      default: () => ({
        sourceUrl: '',
      }),
    },
    isUpdating: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      modelData: null,
      canBeDeleted: false,
    }
  },
  mounted() {
    this.modelData = Object.assign({}, this.data)
    if (this.modelData && this.modelData.id) {
      this.canBeDeleted = true
    }
  },
  methods: {
    save() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if (this.data.id) {
          this.$emit('save', {
            objKey: this.modelData,
            status: 'update',
          })
        } else {
          this.$emit('save', {
            objKey: this.modelData,
            status: 'create',
          })
        }
        this.$v.$reset()
      }
    },
    remove() {
      this.$emit('save', {
        objKey: this.modelData,
        status: 'delete',
      })
    },
    closed() {
      this.$emit('closed')
    },
  },
  validations() {
    return {
      modelData: {
        sourceUrl: {
          required,
          url,
        },
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.modal-url {
  &__input {
    margin-bottom: $gutter-mobile;

    @media (min-width: $screen-sm) {
      margin-bottom: $gutter-tablet;
    }

    &--successive {
      margin-bottom: 8px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
