<template>
  <screen-grid social="location-social">
    <screen-card
      :title="$t('locations.id.general.section.external.title')"
      ratio="1-2"
      display="grid"
      :is-loading="isLoading"
      v-if="externalModules !== null"
    >
      <template v-slot:body>
        <screen-block
          v-for="(value, key) in currentLocation.external"
          :key="`external-${key}`"
          :title="key"
          icon="attribute"
          :has-data="typeof value === 'string'"
          :data="value"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editExternal(value, key)"
        />
        <screen-block
          v-if="!currentLocation.external || Object.keys(currentLocation.external).length === 0"
          :title="$tc('locations.id.general.section.external.label.externalModule', 1)"
          icon="date"
          :has-data="false"
          ratio="1-3"
          :is-loading="isLoading"
          :is-editable="false"
        />
        <screen-action
          :label="$t('locations.id.general.section.external.button.add')"
          icon="add"
          :is-loading="isLoading"
          :disabled="externalModulesAreDisabled"
          @click="editExternal()"
        />
      </template>
    </screen-card>
    <screen-card
      :title="$t('locations.id.general.section.externalUrls.title')"
      ratio="1-2"
      display="grid"
      :is-loading="isLoading"
    >
      <template v-slot:body>
        <screen-block
          v-for="locationUrl in currentLocationUrlsWithoutWebsite"
          :key="locationUrl.id"
          :title="locationUrl.source"
          :data="locationUrl.sourceUrl"
          :is-link="true"
          :has-data="true"
          icon="link"
          class="client-general__feature"
          ratio="1-2"
          :is-loading="isLoading"
          @click="editExternalUrls(locationUrl.source, locationUrl.sourceUrl)"
        />
        <screen-action
          :label="$t('locations.id.general.section.externalUrls.add')"
          icon="add"
          :is-loading="isLoading"
          @click="editExternalUrls()"
        />
      </template>
    </screen-card>
    <screen-card
      :title="$t('locations.id.general.section.externalLinks.title')"
      ratio="1-2"
      display="grid"
      :is-loading="isLoading"
    >
      <template v-slot:body>
        <screen-block
          v-for="externalLink in currentLocation.externalLinks"
          :key="externalLink.id"
          :title="externalLink.title"
          :data="externalLink.url"
          :is-link="true"
          :has-data="true"
          icon="link"
          class="client-general__feature"
          ratio="1-2"
          :is-loading="isLoading"
          @click="editExternalLinks(externalLink)"
        />
        <screen-action
          :label="$t('locations.id.general.section.externalUrls.add')"
          icon="add"
          :is-loading="isLoading"
          @click="editExternalLinks()"
        />
      </template>
    </screen-card>
  </screen-grid>
</template>

<script>
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import ScreenBlock from '@/components/Screen/Block.vue'
import ScreenAction from '@/components/Screen/Action.vue'

export default {
  name: 'LocationSocial',
  components: {
    ScreenGrid,
    ScreenCard,
    ScreenBlock,
    ScreenAction,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentLocation: {
      type: Object,
      required: true,
    },
    currentLocationUrls: {
      type: Array,
      required: true,
    },
    externalModules: {
      type: Object,
      required: false,
      default: null,
    },
  },
  computed: {
    currentLocationUrlsWithoutWebsite() {
      return this.currentLocationUrls.filter(url => url.source !== 'Website')
    },
    externalModulesAreDisabled() {
      if (this.currentLocation.external && this.externalModules) {
        return Object.keys(this.currentLocation.external).length === Object.keys(this.externalModules).length
      }
      return false
    },
  },
  methods: {
    editExternalUrls(source, sourceUrl) {
      this.$emit('editExternalUrls', source, sourceUrl)
    },
    editExternalLinks(externalLink) {
      this.$emit('editExternalLinks', {
        link: externalLink,
        locationExternalLinks: this.currentLocation.externalLinks,
      })
    },
    editExternal(value, key) {
      this.$emit('editExternal', {
        module: {
          key,
          value,
        },
        locationModules: this.currentLocation.external,
        externalModules: this.externalModules,
      })
    },
  },
}
</script>
