<template>
  <modal name="modal-phone" :title="title" :has-apply="true" :is-updating="isPending" @save="save" @closed="closed">
    <template v-slot:container>
      <ui-input v-model.trim="modelData.phone" :label="label" id="modal-phone-input" type="phone" @enter="save">
      </ui-input>
    </template>
  </modal>
</template>

<script>
import UiInput from '@/components/UI/Input.vue'
import { mapActions } from 'vuex'

export default {
  name: 'ModalPhone',
  components: {
    UiInput,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    label: {
      type: [String, Object],
      required: true,
    },
    data: {
      type: [String, Object, Array, Number],
      required: false,
      default: () => ({
        phone: '',
        countryCode: '',
      }),
    },
    isUpdating: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      modelData: {
        phone: '',
        countryCode: '',
      },
      isConverting: false,
    }
  },
  mounted() {
    if (typeof this.data.phone === 'string') {
      this.modelData.phone = this.data.phone
    }
    if (typeof this.data.countryCode === 'string') {
      this.modelData.countryCode = this.data.countryCode
    }
  },
  computed: {
    isPending() {
      return this.isUpdating || this.isConverting
    },
  },
  methods: {
    ...mapActions({
      getInternationalPhone: 'tool/getInternationalPhone',
    }),
    save() {
      this.isConverting = true
      this.getInternationalPhone([
        {
          phone: this.modelData.phone,
          country: this.modelData.countryCode,
        },
      ])
        .then(response => {
          if (!response.error) {
            this.modelData = {
              ...this.modelData,
              internationalPhone: response.e164,
              nationalPhone: response.national,
            }
          }
          this.emitSave()
        })
        .catch(() => this.emitSave())
    },
    closed() {
      this.$emit('closed')
    },
    emitSave() {
      this.isConverting = false
      this.$emit('save', {
        objKey: this.modelData,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-phone {
  &__number {
    padding-bottom: 20px;

    &__label {
      @include input-label;
    }
  }
}
</style>
