<template>
  <screen-grid>
    <screen-card :title="$t('locations.id.locator.section.generalData.title')" display="grid" :is-loading="isLoading">
      <template v-slot:body>
        <screen-block
          :title="$t('locations.id.locator.section.generalData.label.features')"
          icon="category"
          :has-data="hasFeatures"
          ratio="1-2"
          :is-loading="isLoading"
          @click="editFeatures"
        >
          <template v-slot:data>
            <span
              v-for="(feature, idx) in currentFrontOffice.featureList"
              :key="`feature-${idx}`"
              class="location-locator__feature"
            >
              {{ getTitle(feature) }}<template v-if="idx < currentFrontOffice.featureList.length - 1">, </template>
            </span>
          </template>
        </screen-block>
      </template>
    </screen-card>
    <screen-card
      :title="$t('locations.id.locator.section.event.title')"
      ratio="1-2"
      display="grid"
      :is-loading="isLoading"
    >
      <template v-slot:header-right>
        <skeleton-line v-if="isLoading" height="18px" width="160px" margin-left="16px" />
        <ui-switch
          v-else
          ref="customEvents"
          class="location-locator__toggle"
          id="active-news"
          :input-value="isPersonalizedEvents"
          :value="isPersonalizedEvents"
          :reverse="true"
          align="right"
          @input="personalizeContent($event, 'customEvents', 'event')"
        >
          {{ $t('locations.id.locator.section.event.personalized') }}
        </ui-switch>
      </template>
      <template v-slot:body>
        <template v-if="isLoading">
          <screen-flux v-for="n in 2" :key="`event-skeleton-${n}`" :is-loading="isLoading" ratio="1-2" />
        </template>
        <template v-else>
          <template v-if="currentLocation.event && currentLocation.event.length > 0">
            <screen-flux
              v-for="(event, idx) in reverse(currentLocation.event)"
              :key="`event-${idx}`"
              :title="event.title"
              :picture="event.picture"
              :description="event.description"
              :link="event.link"
              icon="link"
              :from="event.from"
              :to="event.to"
              :locale="$i18n.locale"
              ratio="1-2"
              @click="editMedia('event', event)"
            />
          </template>
          <template v-else-if="currentFrontOffice.event && currentFrontOffice.event.length > 0 && !customEvents">
            <screen-flux
              v-for="(event, idx) in currentFrontOffice.event"
              :key="`event-${idx}`"
              :title="event.title"
              :picture="event.picture"
              :description="event.description"
              :link="event.link"
              icon="link"
              :from="event.from"
              :to="event.to"
              :locale="$i18n.locale"
              :is-editable="false"
              ratio="1-2"
            />
          </template>
          <div
            class="location-locator__no-data"
            v-else
            v-html="
              $t('common.label.noDataContent', {
                frontOffice: currentFrontOffice.name,
                locale: currentFrontOffice.localeName,
              })
            "
          ></div>
        </template>
        <div class="location-locator__actions">
          <skeleton-input v-if="isLoading" width="228px" />
          <ui-button
            v-else
            button="primary"
            variant="data"
            icon="add_post"
            :label="$t('locations.id.locator.section.event.button.addEvent')"
            :disabled="!customEvents"
            @click="editMedia('event')"
          />
        </div>
      </template>
    </screen-card>
    <screen-card
      :title="$t('locations.id.locator.section.headline.title')"
      display="grid"
      ratio="1-2"
      :is-loading="isLoading"
    >
      <template v-slot:header-right>
        <skeleton-line v-if="isLoading" height="18px" width="160px" margin-left="16px" />
        <ui-switch
          v-else
          ref="customPictures"
          class="location-locator__toggle"
          id="active-pictures"
          :input-value="isPersonalizedPictures"
          :value="isPersonalizedPictures"
          align="right"
          :reverse="true"
          @input="personalizeContent($event, 'customPictures', 'hotPictures')"
        >
          {{ $t('locations.id.locator.section.headline.personalized') }}
        </ui-switch>
      </template>
      <template v-slot:body>
        <template v-if="isLoading">
          <screen-flux v-for="n in 3" :key="`event-skeleton-${n}`" :is-loading="isLoading" ratio="1-2" />
        </template>
        <template v-else>
          <template v-if="currentLocation.hotPictures && currentLocation.hotPictures.length > 0">
            <screen-flux
              v-for="(hotPicture, idx) in currentLocation.hotPictures"
              :key="`hot-picture-${idx}`"
              :title="hotPicture.name"
              :picture="hotPicture.picture_url"
              :description="hotPicture.description"
              :link="hotPicture.url"
              icon="link"
              ratio="1-2"
              @click="editHotPicture('hotPictures', hotPicture)"
            />
          </template>
          <template
            v-else-if="currentFrontOffice.hotPictures && currentFrontOffice.hotPictures.length > 0 && !customPictures"
          >
            <screen-flux
              v-for="(hotPicture, idx) in currentFrontOffice.hotPictures"
              :key="`hot-picture-${idx}`"
              :title="hotPicture.name"
              :picture="hotPicture.picture_url"
              :description="hotPicture.description"
              :link="hotPicture.url"
              :is-editable="false"
              icon="link"
              ratio="1-2"
            />
          </template>
          <div
            class="location-locator__no-data"
            v-else
            v-html="
              $t('common.label.noDataContent', {
                frontOffice: currentFrontOffice.name,
                locale: currentFrontOffice.localeName,
              })
            "
          ></div>
        </template>
        <div class="location-locator__actions">
          <skeleton-input v-if="isLoading" width="228px" />
          <ui-button
            v-else
            button="primary"
            variant="data"
            icon="add_photo"
            :label="$t('locations.id.locator.section.headline.button.addHeadline')"
            :disabled="!customPictures"
            @click="editHotPicture('hotPictures')"
          />
        </div>
      </template>
    </screen-card>
    <screen-card
      :title="$t('locations.id.locator.section.team.title')"
      ratio="1-2"
      display="grid"
      :is-loading="isLoading"
    >
      <template v-slot:body>
        <template v-if="isLoading">
          <screen-flux v-for="n in 2" :key="`event-skeleton-${n}`" :is-loading="isLoading" ratio="1-2" />
        </template>
        <template v-else>
          <screen-flux
            v-for="(team, idx) in currentLocation.team"
            :key="`team-${idx}`"
            :title="team.title"
            :picture="team.picture"
            :description="team.description"
            :link="team.link"
            icon="link"
            ratio="1-2"
            @click="editMedia('team', team)"
          />
          <div
            class="location-locator__no-data"
            v-if="!currentLocation.team || currentLocation.team.length === 0"
            v-html="
              $t('common.label.noDataAlone', {
                locale: currentFrontOffice.localeName,
              })
            "
          ></div>
        </template>
        <div class="location-locator__actions">
          <skeleton-input v-if="isLoading" width="228px" />
          <ui-button
            v-else
            button="primary"
            variant="data"
            icon="add_person"
            :label="$t('locations.id.locator.section.team.button.addMember')"
            @click="editMedia('team')"
          />
        </div>
      </template>
    </screen-card>
    <screen-card
      :title="$t('locations.id.locator.section.photos.title')"
      ratio="1-2"
      display="grid"
      :is-loading="isLoading"
    >
      <template v-slot:header-right>
        <skeleton-line v-if="isLoading" height="18px" width="160px" margin-left="16px" />
        <ui-switch
          v-else
          ref="customPhotos"
          class="location-locator__toggle"
          id="active-photos"
          :input-value="isPersonalizedPhotos"
          :value="isPersonalizedPhotos"
          :reverse="true"
          align="right"
          @input="personalizeContent($event, 'customPhotos', 'locationPictures')"
        >
          {{ $t('locations.id.locator.section.photos.personalized') }}
        </ui-switch>
      </template>
      <template v-slot:body>
        <template v-if="isLoading">
          <screen-flux v-for="n in 2" :key="`photo-skeleton-${n}`" :is-loading="isLoading" ratio="1-2" />
        </template>
        <template v-else>
          <template v-if="currentLocation.pictures && currentLocation.pictures.length > 0">
            <screen-flux
              v-for="(picture, idx) in currentLocation.pictures"
              :key="`picture-${idx}`"
              :picture="picture"
              ratio="1-2"
              @click="editPicture('locationPictures', picture)"
            />
          </template>
          <template
            v-else-if="
              currentFrontOffice.locationPictures && currentFrontOffice.locationPictures.length > 0 && !customPhotos
            "
          >
            <screen-flux
              v-for="(picture, idx) in currentFrontOffice.locationPictures"
              :key="`picture-${idx}`"
              :picture="picture"
              :is-editable="false"
              ratio="1-2"
            />
          </template>
          <div
            class="location-locator__no-data"
            v-else
            v-html="
              $t('common.label.noDataContent', {
                frontOffice: currentFrontOffice.name,
                locale: currentFrontOffice.localeName,
              })
            "
          ></div>
        </template>
        <div class="location-locator__actions">
          <skeleton-input v-if="isLoading" width="228px" />
          <ui-button
            v-else
            button="primary"
            variant="data"
            icon="add_photo"
            :label="$t('locations.id.locator.section.photos.button.addPhoto')"
            :disabled="!customPhotos"
            @click="editPicture('locationPictures')"
          />
        </div>
      </template>
    </screen-card>
    <screen-card
      :title="$t('locations.id.locator.section.seoContent.title')"
      ratio="1-1"
      display="grid"
      :is-loading="isLoading"
    >
      <template v-slot:header-right>
        <skeleton-line v-if="isLoading" height="18px" width="160px" margin-left="16px" />
        <ui-switch
          v-else
          ref="customSeo"
          class="location-locator__toggle"
          id="active-seo"
          :input-value="isPersonalizedSeo"
          :value="isPersonalizedSeo"
          :reverse="true"
          align="right"
          @input="personalizeContent($event, 'customSeo', 'seo')"
        >
          {{ $t('locations.id.locator.section.seoContent.personalized') }}
        </ui-switch>
      </template>
      <template v-slot:body v-if="isPersonalizedSeo">
        <screen-block
          :title="$t('locations.id.locator.section.seoContent.label.description')"
          icon="text"
          :has-data="typeof currentLocation.description === 'string' && currentLocation.description !== ''"
          :data="currentLocation.description"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('description', 'text', 'textarea')"
        />
        <screen-block
          :title="$t('locations.id.locator.section.seoContent.label.metaDescription')"
          icon="text"
          :has-data="typeof currentLocation.metaDescription === 'string' && currentLocation.metaDescription !== ''"
          :data="currentLocation.metaDescription"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('metaDescription', 'text', 'textarea')"
        />
        <screen-block
          :title="$t('locations.id.locator.section.seoContent.label.metaTitle')"
          icon="text"
          :has-data="typeof currentLocation.metaTitle === 'string' && currentLocation.metaTitle !== ''"
          :data="currentLocation.metaTitle"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('metaTitle', 'text', 'textarea')"
        />
      </template>
      <template v-slot:body v-else>
        <screen-block
          :title="$t('locations.id.locator.section.seoContent.label.description')"
          icon="text"
          :has-data="
            currentFrontOffice.locationDescription !== null &&
            typeof currentFrontOffice.locationDescription[currentLocation.locationType] === 'string' &&
            currentFrontOffice.locationDescription[currentLocation.locationType] !== ''
          "
          :data="
            currentFrontOffice.locationDescription
              ? currentFrontOffice.locationDescription[currentLocation.locationType]
              : ''
          "
          ratio="1-3"
          :is-loading="isLoading"
          :is-editable="false"
        />
        <screen-block
          :title="$t('locations.id.locator.section.seoContent.label.metaDescription')"
          icon="text"
          :has-data="
            currentFrontOffice.locationMetaDescription !== null &&
            typeof currentFrontOffice.locationMetaDescription[currentLocation.locationType] === 'string' &&
            currentFrontOffice.locationMetaDescription[currentLocation.locationType] !== ''
          "
          :data="
            currentFrontOffice.locationMetaDescription
              ? currentFrontOffice.locationMetaDescription[currentLocation.locationType]
              : ''
          "
          ratio="1-3"
          :is-loading="isLoading"
          :is-editable="false"
        />
        <screen-block
          :title="$t('locations.id.locator.section.seoContent.label.metaTitle')"
          icon="text"
          :has-data="
            currentFrontOffice.locationMetaTitle !== null &&
            typeof currentFrontOffice.locationMetaTitle[currentLocation.locationType] === 'string' &&
            currentFrontOffice.locationMetaTitle[currentLocation.locationType] !== ''
          "
          :data="
            currentFrontOffice.locationMetaTitle
              ? currentFrontOffice.locationMetaTitle[currentLocation.locationType]
              : ''
          "
          ratio="1-3"
          :is-loading="isLoading"
          :is-editable="false"
        />
      </template>
    </screen-card>
  </screen-grid>
</template>

<script>
import UiSwitch from '@/components/UI/Switch.vue'
import UiButton from '@/components/UI/Button.vue'
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import ScreenBlock from '@/components/Screen/Block.vue'
import ScreenFlux from '@/components/Screen/Flux.vue'
import SkeletonLine from '@/components/Skeleton/Line.vue'
import SkeletonInput from '@/components/Skeleton/Input.vue'
import reverse from 'lodash/reverse'

export default {
  name: 'LocationLocator',
  components: {
    UiSwitch,
    UiButton,
    ScreenCard,
    ScreenBlock,
    ScreenGrid,
    ScreenFlux,
    SkeletonLine,
    SkeletonInput,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentLocation: {
      type: Object,
      required: true,
    },
    currentFrontOffice: {
      type: Object,
      required: true,
    },
    currentClient: {
      type: Object,
      required: true,
    },
    customEvents: {
      type: Boolean,
      required: true,
    },
    customPictures: {
      type: Boolean,
      required: true,
    },
    customPhotos: {
      type: Boolean,
      required: true,
    },
    customSeo: {
      type: Boolean,
      required: true,
    },
    frontOfficeFeatureList: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      reverse,
    }
  },
  computed: {
    isPersonalizedSeo() {
      return (
        (this.currentLocation.description && this.currentLocation.description !== '') ||
        (this.currentLocation.metaDescription && this.currentLocation.metaDescription !== '') ||
        (this.currentLocation.metaTitle && this.currentLocation.metaTitle !== '') ||
        this.customSeo
      )
    },
    isPersonalizedPhotos() {
      return (this.currentLocation.pictures && this.currentLocation.pictures.length > 0) || this.customPhotos
    },
    isPersonalizedPictures() {
      return (this.currentLocation.hotPictures && this.currentLocation.hotPictures.length > 0) || this.customPictures
    },
    isPersonalizedEvents() {
      return (this.currentLocation.event && this.currentLocation.event.length > 0) || this.customEvents
    },
    hasFeatures() {
      let bool = false
      if (this.currentLocation.featureList && Object.keys(this.currentLocation.featureList).length > 0) {
        for (const key in this.currentLocation.featureList) {
          if (this.currentLocation.featureList[key].length > 0) {
            bool = true
            break
          }
        }
      }
      return bool
    },
  },
  methods: {
    getTitle(feature) {
      let result = feature
      if (this.frontOfficeFeatureList !== null) {
        this.frontOfficeFeatureList.filter(featureListItem =>
          featureListItem.key === feature ? (result = featureListItem.title) : null
        )
      }
      return result
    },
    editSimpleField(objKey, type, mode, required = false, number = null, options = []) {
      this.$emit('editSimpleField', {
        objKey,
        parameters: {
          type,
          mode,
          required,
          number,
          options,
        },
      })
    },
    editFeatures() {
      this.$emit('editFeatures', {
        activeList: this.currentLocation.featureList,
        features: this.currentClient.featureList,
        parameters: {
          mode: 'location',
        },
      })
    },
    personalizeContent(value, ref, type) {
      if (value) {
        this.$emit('showConfirm', {
          parameters: {
            type,
            mode: 'clone',
            element: this.$refs[ref].$el.getElementsByTagName('input')[0],
          },
        })
      } else {
        this.$emit('showConfirm', {
          parameters: {
            type,
            mode: 'reset',
            element: this.$refs[ref].$el.getElementsByTagName('input')[0],
          },
        })
      }
    },
    editMedia(type, media) {
      this.$emit('editMedia', {
        media: media || { frontOfficeId: this.currentFrontOffice.id },
        parameters: {
          type,
        },
      })
    },
    editHotPicture(type, media) {
      this.$emit('editMedia', {
        media: media
          ? {
              id: media.id,
              picture: media.picture_url,
              link: media.url,
              title: media.name,
            }
          : { frontOfficeId: this.currentFrontOffice.id },
        parameters: {
          type,
        },
      })
    },
    editPicture(type, media) {
      this.$emit('editMedia', {
        media: media
          ? {
              id: 1,
              picture: media,
            }
          : { frontOfficeId: this.currentFrontOffice.id },
        parameters: {
          type,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.location-locator {
  &__feature {
    text-transform: capitalize;
  }

  &__flux {
    display: flex;
    flex-direction: column;
    margin: (-$gutter-tablet / 2) (-$gutter-mobile / 2);

    @media (min-width: $screen-sm) {
      flex-direction: row;
      flex-wrap: wrap;
      margin: -12px;
    }

    @media (min-width: $screen-lg) {
      flex-direction: row;
      flex-wrap: wrap;
      margin: $generic-button-offset;
    }

    &__item {
      display: flex;
      position: relative;
      flex-direction: column;
      margin: $gutter-tablet / 2 $gutter-mobile / 2;
      margin-bottom: $gutter-mobile;
      border-radius: 6px;
      box-shadow: 0 0 8px var(--box-shadow-color);
      width: calc(100% - #{$gutter-mobile});
      overflow: hidden;

      @media (min-width: $screen-sm) {
        margin: 12px;
        width: calc(100% / 2 - 24px);
      }

      &:hover {
        box-shadow: 0 0 16px var(--box-shadow-color);
        background-color: var(--bg-color-hover);
        cursor: pointer;
      }

      &__img {
        display: inline-flex;
        width: 100%;

        .location-locator__flux__item:hover & {
          filter: brightness(0.8);
        }
      }

      &__title {
        margin: 12px 12px 4px;
        font-weight: 600;
      }

      &__content {
        @include text-ellipsis(3, 14px);

        margin: 4px 12px 12px;
        color: var(--text-color-alt);
      }

      &__link {
        display: flex;
        align-items: center;
        margin: 4px 12px 12px;

        &__icon {
          margin-right: 8px;
          color: map-get($generic-color-variants, 'linkedin');
          font-size: $font-size-lg;
        }

        &__url {
          color: var(--text-color-alt);
          font-size: $font-size-sm;
        }
      }
    }
  }

  &__no-data {
    margin: $gutter-mobile / 2;
    color: var(--text-color-alt);
    font-style: italic;

    @media (min-width: $screen-sm) {
      margin: $gutter-tablet / 2;
    }

    @media (min-width: $screen-xl) {
      margin: $gutter-desktop / 2;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: $gutter-mobile / 2;
    margin-bottom: $gutter-mobile / 2;
    width: 100%;

    @media (min-width: $screen-sm) {
      margin-top: $gutter-tablet / 2;
      margin-bottom: $gutter-tablet / 2;
    }

    @media (min-width: $screen-xl) {
      margin-top: $gutter-desktop / 2;
      margin-bottom: $gutter-desktop / 2;
    }
  }
}
</style>
