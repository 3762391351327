<template>
  <div class="locations-id" @scroll.passive="onScroll($event.srcElement, tabsElement)">
    <screen-header
      class="locations-id__header"
      :title="currentLocation.name"
      :breadcrumbs="breadcrumbs"
      :is-loading="isLoading"
      v-if="!hasError"
      :right-content="frontOffices.length > 0"
    >
      <div class="locations-id__header__link-to-location__button" v-if="isLoading">
        <skeleton-input margin-left="24px" />
      </div>
      <ui-button
        :label="$t('locations.id.header.goToLocation.label')"
        :is-link="true"
        :href="currentLocationLink"
        button="cta"
        variant="data"
        icon="external_link"
        class="locations-id__header__link-to-location__button"
        v-else
      />

      <skeleton-icon v-if="isLoading" margin-left="16px" width="44px" height="44px" />
      <v-popover
        placement="bottom-end"
        :autoHide="true"
        container=".locations-id__header"
        popoverInnerClass="popover-inner no-overflow"
        v-else
      >
        <ui-button
          class="locations-id__header__cta"
          button="primary"
          variant="data"
          icon="store"
          :label="`${currentFrontOffice.name} – ${
            currentFrontOffice.localeCode ? currentFrontOffice.localeCode.toUpperCase() : ''
          }`"
          :mobile-toggle="true"
          v-if="frontOffices.length > 1 || currentFrontOfficeLocales.length > 1"
        />
        <template slot="popover">
          <div class="locations-id__header__popover">
            <ui-dropdown
              v-if="frontOffices.length > 1"
              class="locations-id__header__popover__dropdown"
              id="dropdown-front-offices"
              v-model="modelFrontOffice"
              :options="frontOffices"
              :placeholder="$t('locations.id.header.frontOffice.placeholder')"
              :dropdown-label="$t('locations.id.header.frontOffice.label')"
              label="name"
              track-by="id"
              :show-label="true"
            />
            <ui-dropdown
              v-if="frontOffices.length > 0 && currentFrontOfficeLocales.length > 1"
              class="locations-id__header__popover__dropdown"
              id="dropdown-front-offices"
              v-model="modelLocale"
              :options="currentFrontOfficeLocales"
              :placeholder="$t('locations.id.header.language.placeholder')"
              :dropdown-label="$t('locations.id.header.language.label')"
              label="localeName"
              track-by="localeId"
              :show-label="true"
            />
          </div>
        </template>
      </v-popover>
    </screen-header>
    <screen-error v-if="hasError" :route="{ name: 'Locations' }" :label="$t('locations.id.error.button.back')" />
    <screen-container direction="column" v-else>
      <screen-tabs
        :tabs="tabs"
        :element-fixed="elementFixed"
        :current-tab="currentTab"
        :is-loading="isLoading"
        @mounted="onTabsMounted"
        @click="setCurrentTab"
      />
      <!-- General Section -->
      <location-general
        v-if="currentTab === 'general'"
        :is-loading="isLoading"
        :current-location="currentLocation"
        :current-location-urls="currentLocationUrls"
        :current-location-opening-hours="currentLocationOpeningHours"
        :current-location-exceptional-opening-hours="currentLocationExceptionalOpeningHours"
        :current-client="currentClient"
        :countries="countries"
        :sub-divisions="subDivisions"
        :external-modules="externalModules"
        :external-links-provider="externalLinksProvider"
        :front-offices="frontOffices"
        @editSimpleField="editSimpleField"
        @editAddress="editAddress"
        @editPhone="editPhone"
        @editCoordinates="editCoordinates"
        @editOpeningHours="editOpeningHours"
        @editExceptionalHours="editExceptionalHours"
        @editWebsite="editWebsite"
        @editSubDivisions="editSubDivisions"
        @sortExceptionalOpeningHour="sortExceptionalOpeningHour"
        @updateLocationStatus="updateLocationStatus"
        @updateProductLocator="updateProductLocator"
      />

      <!-- Locator Section -->
      <location-locator
        v-if="currentFrontOffice && currentFrontOffice.id && currentTab === 'locator'"
        :is-loading="isLoading"
        :current-location="currentLocation"
        :current-front-office="currentFrontOffice"
        :current-client="currentClient"
        :custom-events="isPersonalizedEvents"
        :custom-pictures="isPersonalizedPictures"
        :custom-photos="isPersonalizedPhotos"
        :custom-seo="isPersonalizedSeo"
        :front-office-feature-list="featureListDetails"
        @editSimpleField="editSimpleField"
        @editFeatures="editFeatures"
        @editMedia="editMedia"
        @showConfirm="showConfirm"
      />

      <!-- Social Section -->
      <location-social
        v-if="currentTab === 'social'"
        :is-loading="isLoading"
        :current-location="currentLocation"
        :current-location-urls="currentLocationUrls"
        :external-modules="externalModules"
        @editExternal="editExternal"
        @editExternalUrls="editExternalUrls"
        @editExternalLinks="editExternalLinks"
      />

      <!-- Product Locator Section -->
      <location-product-locator
        v-if="currentTab === 'product' && activeClientLocator"
        :is-loading="isLoading"
        :current-location="currentLocation"
        :current-client="currentClient"
        @editSimpleField="editSimpleField"
        @updateProductLocator="updateProductLocator"
      />
    </screen-container>
    <modal-simple-field
      v-if="isEditing && editingModal === 'field'"
      :title="modalTitle"
      :is-updating="isUpdating"
      :data="modal.data"
      :label="modal.label"
      :obj-key="modal.objKey"
      :parameters="modal.parameters"
      @save="saveSimpleField"
      @closed="closedModal"
    />
    <modal-address
      v-if="isEditing && editingModal === 'address'"
      :title="modalTitle"
      :is-updating="isUpdating"
      :data="modal.data"
      @save="saveSimpleField"
      @closed="closedModal"
    />
    <modal-phone
      v-if="isEditing && editingModal === 'phone'"
      :title="modalTitle"
      :is-updating="isUpdating"
      :data="modal.data"
      :label="modal.label"
      @save="saveSimpleField"
      @closed="closedModal"
    />
    <modal-coordinates
      v-if="isEditing && editingModal === 'coordinates'"
      :title="modalTitle"
      :is-updating="isUpdating"
      :data="modal.data"
      @save="saveSimpleField"
      @closed="closedModal"
    />
    <modal-url
      v-if="isEditing && editingModal === 'url'"
      :title="modalTitle"
      :is-updating="isUpdating"
      :data="modal.data"
      :label="modal.label"
      @save="saveWebsite"
      @closed="closedModal"
    />
    <modal-hours
      v-if="isEditing && editingModal === 'openingHours'"
      :title="modalTitle"
      :is-updating="isUpdating"
      :data="modal.data"
      @save="saveOpeningHours"
      @closed="closedModal"
    />
    <modal-exceptional-hours
      v-if="isEditing && editingModal === 'exceptionalHours'"
      :title="modalTitle"
      :is-updating="isUpdating"
      :data="modal.data"
      @save="saveExceptionalHours"
      @closed="closedModal"
    />
    <modal-media
      v-if="isEditing && editingModal === 'media'"
      :title="modalTitle"
      :is-updating="isUpdating"
      :data="modal.data"
      :parameters="modal.parameters"
      @save="updateMedia"
      @add="addMedia"
      @remove="removeMedia"
      @closed="closedModal"
    />
    <modal-confirm
      v-if="isEditing && editingModal === 'confirm'"
      :parameters="modal.parameters"
      :is-updating="isUpdating"
      @save="cloneMedia"
      @cancel="cancelMedia"
      @closed="closedModal"
    />
    <modal-feature
      v-if="isEditing && editingModal === 'feature'"
      :title="modalTitle"
      :is-updating="isUpdating"
      :features="modal.features"
      :featuresDetails="featureListDetails"
      :active-list="modal.activeList"
      :parameters="modal.parameters"
      @save="saveSimpleField"
      @closed="closedModal"
    />
    <modal-sub-divisions
      v-if="isEditing && editingModal === 'subDivisions'"
      :title="modalTitle"
      :is-updating="isUpdating"
      :data="modal.data"
      :sub-divisions="modal.subDivisions"
      :currentCoordinates="currentLocation"
      @save="saveSimpleField"
      @closed="closedModal"
    />
    <modal-external-modules
      v-if="isEditing && editingModal === 'externalModules'"
      :title="modalTitle"
      :is-updating="isUpdating"
      :data="modal.data"
      :external-modules="modal.externalModules"
      :location-modules="modal.locationModules"
      @save="saveSimpleField"
      @closed="closedModal"
    />
    <modal-external-urls
      v-if="isEditing && editingModal === 'externalUrls'"
      :title="modalTitle"
      :is-updating="isUpdating"
      :locationUrls="currentLocationUrls"
      :data="modal.data"
      @save="saveExternalUrls"
      @remove="removeExternalUrl"
      @closed="closedModal"
    />
    <modal-external-links
      v-if="isEditing && editingModal === 'externalLinks'"
      :title="modalTitle"
      :is-updating="isUpdating"
      :locationExternalLinks="modal.locationExternalLinks"
      :data="modal.data"
      @save="saveExternalLinks"
      @closed="closedModal"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { onScroll } from '@/mixins/scroll.mixin'
import { notif } from '@/mixins/notification.mixin'
import ScreenHeader from '@/components/Screen/Header.vue'
import ScreenContainer from '@/components/Screen/Container.vue'
import ScreenTabs from '@/components/Screen/Tabs.vue'
import ScreenError from '@/components/Screen/Error.vue'
import UiButton from '@/components/UI/Button.vue'
import UiDropdown from '@/components/UI/Dropdown.vue'
import SkeletonIcon from '@/components/Skeleton/Icon.vue'
import SkeletonInput from '@/components/Skeleton/Input.vue'
import ModalSimpleField from '@/components/Modal/SimpleField.vue'
import ModalAddress from '@/components/Modal/Address.vue'
import ModalCoordinates from '@/components/Modal/Coordinates.vue'
import ModalUrl from '@/components/Modal/Url.vue'
import ModalPhone from '@/components/Modal/Phone.vue'
import ModalHours from '@/components/Modal/Hours.vue'
import ModalExceptionalHours from '@/components/Modal/ExceptionalHours.vue'
import ModalMedia from '@/components/Modal/Media.vue'
import ModalConfirm from '@/components/Modal/Confirm.vue'
import ModalFeature from '@/components/Modal/Feature.vue'
import ModalSubDivisions from '@/components/Modal/SubDivisions.vue'
import ModalExternalModules from '@/components/Modal/ExternalModules.vue'
import ModalExternalUrls from '@/components/Modal/ExternalUrls.vue'
import ModalExternalLinks from '@/components/Modal/ExternalLinks.vue'
import LocationGeneral from '@/components/Location/General.vue'
import LocationProductLocator from '@/components/Location/ProductLocator.vue'
import LocationLocator from '@/components/Location/Locator.vue'
import LocationSocial from '@/components/Location/Social.vue'
import { timeFromNow } from '@/utils/hours.util'
import { mediaParser } from '@/utils/media.util'

export default {
  name: 'LocationsId',
  components: {
    ScreenHeader,
    ScreenContainer,
    ScreenTabs,
    ScreenError,
    UiButton,
    UiDropdown,
    SkeletonIcon,
    SkeletonInput,
    ModalSimpleField,
    ModalAddress,
    ModalCoordinates,
    ModalUrl,
    ModalPhone,
    ModalHours,
    ModalExceptionalHours,
    ModalMedia,
    ModalConfirm,
    ModalFeature,
    ModalSubDivisions,
    ModalExternalModules,
    ModalExternalUrls,
    ModalExternalLinks,
    LocationGeneral,
    LocationProductLocator,
    LocationLocator,
    LocationSocial,
  },
  mixins: [onScroll, notif],
  data() {
    return {
      isLoading: false,
      isEditing: false,
      editingModal: '',
      modal: {
        objKey: null,
        data: null,
        label: null,
        parameters: {
          type: 'text',
          mode: 'input',
          required: false,
          number: null,
        },
      },
      hasError: false,
      tabsElement: null,
      customEvents: false,
      customPictures: false,
      customPhotos: false,
      customSeo: false,
    }
  },
  async created() {
    if (!this.$route.params.currentTab) {
      this.setCurrentTab('general')
    }
    this.isLoading = true
    try {
      await this.getFrontOffices({})
      if (this.frontOffices?.length > 0) {
        const currentFrontOffice = localStorage.getItem('frontoffice')
          ? JSON.parse(localStorage.getItem('frontoffice'))
          : this.frontOffices[0]
        await this.getFrontOffice({
          frontOfficeId: currentFrontOffice.id,
          localeId: currentFrontOffice.defaultLocaleId,
        })
        //trigger getLocation with watch handler
      } else {
        await this.getLocation({ locationId: this.$route.params.id })
      }
    } catch (err) {
      this.$log.error(err)
      this.hasError = true
    } finally {
      this.isLoading = false
    }
  },
  watch: {
    currentFrontOffice: {
      deep: true,
      handler() {
        this.isLoading = true
        this.getFrontOfficeFeaturesList()
        this.getLocation({ locationId: this.$route.params.id })
          .then(() => {
            this.isLoading = false
          })
          .catch(err => {
            this.$log.error(err)
            this.isLoading = false
            this.hasError = true
          })
      },
    },
  },
  // async mounted() {
  //   this.isLoading = true
  //   try {
  //     await this.getFrontOfficeFeaturesList()
  //   } catch {
  //     this.hasError = true
  //   } finally {
  //     this.isLoading = false
  //   }
  // },
  computed: {
    ...mapState({
      currentLocation: state => state.location.currentLocation,
      currentLocationUrls: state => state.location.currentLocationUrls,
      currentLocationOpeningHours: state => state.location.currentLocationOpeningHours,
      currentLocationExceptionalOpeningHours: state => state.location.currentLocationExceptionalOpeningHours,
      currentClient: state => state.user.currentClient,
      frontOffices: state => state.frontoffice.frontOffices,
      currentFrontOffice: state => state.frontoffice.currentFrontOffice,
      countries: state => state.user.countries,
      subDivisions: state => state.tool.subDivisions,
      externalModules: state => state.user.externalModules,
      externalLinksProvider: state => state.user.externalLinksProvider,
      featureListDetails: state => state.frontoffice.currentFrontOfficeFeatureList,
    }),
    activeClientLocator() {
      return this.currentClient.productLocatorStatus === '1'
    },
    activeLocationLocator() {
      return this.currentLocation.productLocatorStatus === '1'
    },
    currentTab() {
      return this.$route.params.currentTab
    },
    breadcrumbs() {
      return [
        {
          label: this.$t('locations.list.breadcrumb'),
          route: {
            name: 'Locations',
          },
        },
        {
          label: this.currentLocation.name,
          route: {
            name: 'Location',
            params: {
              id: this.currentLocation.id,
            },
          },
        },
      ]
    },
    tabs() {
      const tabs = []
      if (this.currentFrontOffice && this.currentFrontOffice.id) {
        tabs.push(
          {
            label: this.$t('locations.id.tab.general'),
            key: 'general',
          },
          {
            label: this.$t('locations.id.tab.locator'),
            key: 'locator',
          },
          {
            label: this.$t('locations.id.tab.social'),
            key: 'social',
          }
        )
      } else {
        tabs.push(
          {
            label: this.$t('locations.id.tab.general'),
            key: 'general',
          },
          {
            label: this.$t('locations.id.tab.social'),
            key: 'social',
          }
        )
      }

      if (this.activeClientLocator) {
        tabs.push({
          label: this.$t('locations.id.tab.productLocator'),
          key: 'product',
          status: {
            display: true,
            active: this.activeLocationLocator,
          },
        })
      }
      return tabs
    },
    modelFrontOffice: {
      get() {
        return this.currentFrontOffice
      },
      set(fo) {
        this.isLoading = true
        this.updateCurrentFrontOffice(fo)
      },
    },
    modelLocale: {
      get() {
        return this.currentFrontOffice
      },
      set(locale) {
        this.isLoading = true
        this.updateCurrentFrontOfficeLocale(locale)
      },
    },
    currentLocationLink() {
      const { url: frontOfficeUrl, localeCode: frontOfficeLocale } = this.currentFrontOffice
      const { id: locationId, slug: locationSlug } = this.currentLocation
      if (typeof locationSlug !== 'string') {
        return `https://${frontOfficeUrl}/${frontOfficeLocale}/${locationId}`
      }
      return `https://${frontOfficeUrl}/${frontOfficeLocale}/${locationSlug}-${locationId}`
    },
    currentFrontOfficeLocales() {
      if (this.frontOffices.length) {
        const currentFrontOffice = this.frontOffices.find(fo => fo.id === this.currentFrontOffice.id)
        return Array.isArray(currentFrontOffice?.locales) ? currentFrontOffice.locales : []
      }
      return []
    },
    modalTitle() {
      return this.$t('common.modal.title.location', {
        location: this.currentLocation.name,
      })
    },
    isPersonalizedSeo() {
      return (
        (this.currentLocation.description && this.currentLocation.description !== '') ||
        (this.currentLocation.metaDescription && this.currentLocation.metaDescription !== '') ||
        (this.currentLocation.metaTitle && this.currentLocation.metaTitle !== '') ||
        this.customSeo
      )
    },
    isPersonalizedPhotos() {
      return (this.currentLocation.pictures && this.currentLocation.pictures.length > 0) || this.customPhotos
    },
    isPersonalizedPictures() {
      return (this.currentLocation.hotPictures && this.currentLocation.hotPictures.length > 0) || this.customPictures
    },
    isPersonalizedEvents() {
      return (this.currentLocation.event && this.currentLocation.event.length > 0) || this.customEvents
    },
  },
  methods: {
    ...mapActions({
      getLocation: 'location/getLocation',
      getFrontOffices: 'frontoffice/getFrontOffices',
      getFrontOffice: 'frontoffice/getFrontOffice',
      updateLocation: 'location/updateLocation',
      createUrl: 'location/createUrl',
      updateUrl: 'location/updateUrl',
      deleteUrl: 'location/deleteUrl',
      updateOpeningHour: 'location/updateOpeningHour',
      updateExceptionalOpeningHour: 'location/updateExceptionalOpeningHour',
      getExceptionalOpeningHour: 'location/getExceptionalOpeningHour',
      updateCurrentFrontOffice: 'frontoffice/updateCurrentFrontOffice',
      updateCurrentFrontOfficeLocale: 'frontoffice/updateCurrentFrontOfficeLocale',
      uploadToolMedia: 'tool/uploadToolMedia',
      removeToolMedia: 'tool/removeToolMedia',
      cloneToolMedia: 'tool/cloneToolMedia',
      getFrontOfficeFeaturesList: 'frontoffice/getFrontOfficeFeaturesList',
    }),
    onTabsMounted(element) {
      this.tabsElement = element
    },
    formatedDate(date) {
      return timeFromNow(date, this.$i18n.locale, false)
    },
    editSimpleField(payload) {
      this.modal.objKey = payload.objKey
      this.modal.label = this.$t(`locations.id.modal.${payload.objKey}`)
      this.modal.data = this.currentLocation[payload.objKey]
      this.modal.parameters = payload.parameters
      this.isEditing = true
      this.editingModal = 'field'
      this.$nextTick(() => {
        this.$modal.show('modal-simple-field')
      })
    },
    editAddress(payload) {
      this.modal.data = payload.address
      this.isEditing = true
      this.editingModal = 'address'
      this.$nextTick(() => {
        this.$modal.show('modal-address')
      })
    },
    editPhone(payload) {
      this.modal.data = {
        phone: payload.phone,
        countryCode: payload.countryCode,
      }
      this.modal.label = this.$t('locations.id.modal.phone')
      this.isEditing = true
      this.editingModal = 'phone'
      this.$nextTick(() => {
        this.$modal.show('modal-phone')
      })
    },
    editCoordinates(payload) {
      this.modal.data = payload.coordinates
      this.isEditing = true
      this.editingModal = 'coordinates'
      this.$nextTick(() => {
        this.$modal.show('modal-coordinates')
      })
    },
    editOpeningHours(payload) {
      this.modal.data = payload.hours
      this.isEditing = true
      this.editingModal = 'openingHours'
      this.$nextTick(() => {
        this.$modal.show('modal-hours')
      })
    },
    editExceptionalHours(payload) {
      this.modal.data = payload.exceptionalHour
      this.isEditing = true
      this.editingModal = 'exceptionalHours'
      this.$nextTick(() => {
        this.$modal.show('modal-exceptional-hours')
      })
    },
    editWebsite(payload) {
      this.modal.data = payload.url
      this.modal.label = this.$t('locations.id.modal.website')
      this.isEditing = true
      this.editingModal = 'url'
      this.$nextTick(() => {
        this.$modal.show('modal-url')
      })
    },
    editFeatures(payload) {
      this.modal.features = payload.features
      this.modal.activeList = payload.activeList
      this.modal.parameters = payload.parameters
      this.isEditing = true
      this.editingModal = 'feature'
      this.$nextTick(() => {
        this.$modal.show('modal-feature')
      })
    },
    editMedia(payload) {
      this.modal.data = payload.media
      this.modal.parameters = payload.parameters
      this.isEditing = true
      this.editingModal = 'media'
      this.$nextTick(() => {
        this.$modal.show('modal-media')
      })
    },
    editSubDivisions(payload) {
      this.modal.data = payload.locationDivisions
      this.modal.subDivisions = payload.subDivisions
      this.isEditing = true
      this.editingModal = 'subDivisions'
      this.$nextTick(() => {
        this.$modal.show('modal-sub-divisions')
      })
    },
    editExternalUrls(source = null, sourceUrl = null) {
      this.modal.data = { source, sourceUrl }
      this.isEditing = true
      this.editingModal = 'externalUrls'
      this.$nextTick(() => {
        this.$modal.show('modal-external-urls')
      })
    },
    editExternalLinks(payload) {
      this.modal.data = payload.link
      this.modal.locationExternalLinks = payload.locationExternalLinks || []
      this.isEditing = true
      this.editingModal = 'externalLinks'
      this.$nextTick(() => {
        this.$modal.show('modal-external-links')
      })
    },
    editExternal(payload) {
      this.modal.data = payload.module
      this.modal.externalModules = payload.externalModules
      this.modal.locationModules = payload.locationModules
      this.isEditing = true
      this.editingModal = 'externalModules'
      this.$nextTick(() => {
        this.$modal.show('modal-external-modules')
      })
    },
    showConfirm(payload) {
      this.modal.parameters = payload.parameters
      this.isEditing = true
      this.editingModal = 'confirm'
      this.$nextTick(() => {
        this.$modal.show('modal-confirm')
      })
    },
    saveSimpleField(params) {
      this._createLocationUpdate(async () => {
        await this.updateLocation(params.objKey)
      }, ['modal-simple-field', 'modal-address', 'modal-coordinates', 'modal-feature', 'modal-choice'])
    },
    saveWebsite(params) {
      this._createLocationUpdate(async () => {
        await this.updateUrl({ url: params.objKey, status: params.status })
      }, 'modal-url')
    },
    removeExternalUrl({ id }) {
      this._createLocationUpdate(async () => {
        await this.updateUrl({
          url: { id },
          status: 'delete',
        })
      }, 'modal-external-urls')
    },
    saveExternalUrls({ source, sourceUrl, id }) {
      this._createLocationUpdate(async () => {
        const status = id ? 'update' : 'create'
        await this.updateUrl({
          url: { source, sourceUrl, id },
          status,
        })
      }, 'modal-external-urls')
    },
    saveExternalLinks(params) {
      this._createLocationUpdate(async () => {
        await this.updateLocation(params.objKey)
      }, 'modal-external-links')
    },
    saveOpeningHours(params) {
      this._createLocationUpdate(async () => {
        await this.updateOpeningHour({ openingHour: params.objKey, status: params.status })
      }, 'modal-hours')
    },
    saveExceptionalHours(params) {
      this._createLocationUpdate(async () => {
        await this.updateExceptionalOpeningHour({ exceptionalOpeningHour: params.objKey, status: params.status })
      }, 'modal-exceptional-hours')
    },
    updateMedia(params) {
      this._createLocationUpdate(async () => {
        const mediaUrl = await this.uploadToolMedia({
          title: params.media.title,
          image: params.image,
          picture: params.media.picture,
          type: params.type,
          name: this.currentLocation.name,
          element: this.currentLocation.id,
          frontOfficeId: this.currentFrontOffice.id,
        })
        await this.updateLocation({
          [params.type]: mediaParser(this.currentLocation[params.type], params.type, 'edit', params.media, mediaUrl),
        })
      }, 'modal-media')
    },
    addMedia(params) {
      this._createLocationUpdate(async () => {
        const mediaUrl = await this.uploadToolMedia({
          title: params.media.title,
          image: params.image,
          picture: params.media.picture,
          type: params.type,
          name: this.currentLocation.name,
          element: this.currentLocation.id,
          frontOfficeId: params.type !== 'locationPictures' ? this.currentFrontOffice.id : null,
        })
        const type = params.type === 'locationPictures' ? 'pictures' : params.type
        await this.updateLocation({
          [type]: mediaParser(this.currentLocation[type], params.type, 'add', params.media, mediaUrl),
        })
      }, 'modal-media')
    },
    removeMedia(params) {
      this._createLocationUpdate(async () => {
        await this.removeToolMedia({
          picture: params.media.picture,
        })
        const type = params.type === 'locationPictures' ? 'pictures' : params.type
        await this.updateLocation({
          [type]: mediaParser(this.currentLocation[type], params.type, 'delete', params.media),
        })
      }, 'modal-media')
    },

    cloneSeo(params) {
      this._createLocationUpdate(async () => {
        if (params.mode === 'clone') {
          await this.updateLocation({
            description: this.currentFrontOffice.locationDescription
              ? this.currentFrontOffice.locationDescription[this.currentLocation.locationType]
              : '',
            metaDescription: this.currentFrontOffice.locationMetaDescription
              ? this.currentFrontOffice.locationMetaDescription[this.currentLocation.locationType]
              : '',
            metaTitle: this.currentFrontOffice.locationMetaTitle
              ? this.currentFrontOffice.locationMetaTitle[this.currentLocation.locationType]
              : '',
          })
          this.customSeo = true
        }
        if (params.mode === 'reset') {
          await this.updateLocation({
            description: '',
            metaDescription: '',
            metaTitle: '',
          })
          this.customSeo = false
        }
      }, 'modal-confirm')
    },
    cloneMedia(params) {
      if (params.type === 'seo') {
        return this.cloneSeo(params)
      }
      this.isUpdating = true
      if (params.mode === 'clone') {
        const cloneMediaPromises = this.currentFrontOffice[params.type]
          ? this.currentFrontOffice[params.type].map(element => {
              let picture = null
              switch (params.type) {
                case 'hotPictures':
                  picture = element.picture_url
                  break
                case 'event':
                  picture = element.picture
                  break
                default:
                  picture = element
              }
              return this.cloneToolMedia({
                title: element.title || element.name || '',
                picture,
                type: params.type,
                name: this.currentLocation.name,
                element: this.currentLocation.id,
              })
            })
          : []
        Promise.all(cloneMediaPromises)
          .then(urls => {
            const cloneMedias = this.currentFrontOffice[params.type]
              ? this.currentFrontOffice[params.type].map((element, idx) =>
                  mediaParser(
                    null,
                    params.type,
                    'clone',
                    {
                      id: element.id,
                      title: element.title || element.name,
                      link: element.link || element.url,
                      description: element.description,
                      from: element.from,
                      to: element.to,
                    },
                    urls[idx]
                  )
                )
              : []
            const type = params.type === 'locationPictures' ? 'pictures' : params.type
            return this.updateLocation({
              [type]: cloneMedias,
            })
          })
          .then(() => {
            this.$modal.hide('modal-confirm')
            this.closedModal()
            if (params.type === 'event') {
              this.customEvents = true
            } else if (params.type === 'hotPictures') {
              this.customPictures = true
            } else if (params.type === 'locationPictures') {
              this.customPhotos = true
            }
          })
          .catch(() => this.notificationError())
      }
      if (params.mode === 'reset') {
        const type = params.type === 'locationPictures' ? 'pictures' : params.type
        const removeMediaPromises = this.currentLocation[type]
          ? this.currentLocation[type].map(element => {
              let picture = null
              switch (params.type) {
                case 'hotPictures':
                  picture = element.picture_url
                  break
                case 'event':
                  picture = element.picture
                  break
                default:
                  picture = element
              }
              return this.removeToolMedia({ picture })
            })
          : []
        Promise.all(removeMediaPromises)
          .then(() => {
            return this.updateLocation({
              [type]: [],
            })
          })
          .then(() => {
            this.$modal.hide('modal-confirm')
            this.closedModal()
            if (params.type === 'event') {
              this.customEvents = false
            } else if (params.type === 'hotPictures') {
              this.customPictures = false
            } else if (params.type === 'locationPictures') {
              this.customPhotos = false
            }
          })
          .catch(() => this.notificationError())
      }
    },
    async updateLocationStatus(status) {
      try {
        await this.updateLocation({ status })
        this.notificationSuccess('location', this.currentLocation.name)
      } catch (err) {
        this.$log.error(err)
        this.notificationError()
      }
    },
    async updateProductLocator(productLocatorStatus) {
      try {
        await this.updateLocation({ productLocatorStatus })
        this.notificationSuccess('location', this.currentLocation.name)
      } catch (err) {
        this.$log.error(err)
        this.notificationError()
      }
    },
    async _createLocationUpdate(updateFn, modaleNames = []) {
      this.isUpdating = true
      try {
        await updateFn()
        this.notificationSuccess('location', this.currentLocation.name)
      } catch (err) {
        this.$log.error(err)
        this.notificationError()
      } finally {
        ;[].concat(modaleNames).forEach(name => this.$modal.hide(name))
        this.closedModal()
      }
    },
    cancelMedia(params) {
      if (params.mode === 'clone') {
        params.element.checked = false
      } else if (params.mode === 'reset') {
        params.element.checked = true
      }
    },
    closedModal() {
      this.isEditing = false
      this.isUpdating = false
      this.editingModal = ''
    },
    sortExceptionalOpeningHour(period) {
      this.getExceptionalOpeningHour({ period, locationId: this.currentLocation.id })
    },
    setCurrentTab(tab) {
      this.$router.replace({ ...this.$route, params: { ...this.$route.params, currentTab: tab } })
    },
  },
}
</script>

<style lang="scss">
.locations-id {
  padding-bottom: $button-min-height + $gutter-mobile * 2;

  @media (min-width: $screen-sm) {
    padding-bottom: 0;
  }

  &__header {
    &__cta {
      margin-left: $gutter-mobile;

      @media (min-width: $screen-sm) {
        margin-left: $gutter-tablet;
      }

      @media (min-width: $screen-xl) {
        margin-left: $gutter-desktop;
      }
    }

    &__link-to-location {
      &__button {
        position: fixed;
        right: $gutter-mobile;
        bottom: calc(#{$navigation-height} + #{$gutter-mobile});
        z-index: 2;

        @media (min-width: $screen-sm) {
          position: relative;
          right: initial;
          bottom: initial;
          margin-left: $gutter-tablet;
        }

        @media (min-width: $screen-xl) {
          margin-left: $gutter-desktop;
        }
      }
    }

    &__popover {
      padding: $gutter-mobile;
      min-width: 280px;

      @media (min-width: $screen-sm) {
        padding: $gutter-tablet;
        min-width: 340px;
      }

      &__dropdown {
        margin-top: $gutter-mobile;

        @media (min-width: $screen-sm) {
          margin-top: $gutter-tablet;
        }

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  &__medias {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: -3px;

    @media (min-width: $screen-sm) {
      margin: -6px;
    }

    &__item {
      display: inline-flex;
      position: relative;
      margin: 3px;
      width: calc(100% / 2 - 6px);

      @media (min-width: $screen-sm) {
        margin: 6px;
        width: calc(100% / 2 - 12px);
      }

      &__img {
        display: block;
        width: 100%;
      }

      &__top {
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        align-items: center;
        justify-content: space-between;
        background-color: rgba(0, 0, 0, 0.2);
        padding: 4px 8px;

        @media (min-width: $screen-sm) {
          padding: 8px;
        }

        &__button {
          margin: $generic-button-offset;
          color: #fff;
        }

        &__label {
          color: #fff;
        }
      }

      &__bottom {
        display: flex;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        align-items: center;
        justify-content: space-between;
        background: rgba(0, 0, 0, 0.2);
        padding: 4px 8px;
        color: #fff;

        @media (min-width: $screen-sm) {
          padding: 8px;
        }

        &__views {
          display: flex;
          align-items: center;

          &__icon {
            margin-right: 8px;
            font-size: 1rem;
          }
        }
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: $gutter-mobile;
    margin-bottom: $gutter-mobile / 2;
    width: 100%;

    @media (min-width: $screen-sm) {
      margin-top: $gutter-tablet;
      margin-bottom: $gutter-tablet / 2;
    }

    @media (min-width: $screen-xl) {
      margin-top: $gutter-desktop;
      margin-bottom: $gutter-desktop / 2;
    }
  }
}
</style>
